import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CheckboxAnswerInput from './CheckboxAnswerInput';
import FreeAnswerInput from './FreeAnswerInput';
import { FormGroup, Card, Typography, Alert } from '@mui/material';
import { useThemeConfig } from '@applica-software-guru/react-admin';

// simple question input (checkboxes)
// labelled question input (labels + radio groups)

// sono presenti labels nella question?
// no => input base: checkbox + test risposta <CheckboxGroupInput>
//       freeform: checkbox + testo risposta + text input  (per ora solo selezione, poi creare input custom)

// sì => riga con labels <RadioGroupInput>

const SimpleQuestionInput = (props) => {
  // mantengo lo stato interno come array di pseudo-oggetti "submission"
  const { spacing } = useThemeConfig();

  const question = props.question;
  const answers = question.answers;
  //TODO: test if this is actually necessary.
  const onValidate = () => console.log('SimpleQuestionInput - onValidate');

  const [numAnswers, setNumAnswers] = useState(0);

  const validate = (event) => {
    let newNumAnswers = event.target.checked ? numAnswers + 1 : numAnswers - 1;
    // send question id + error state to parent to signal the error state;
    // { 'question-id': true } => parent considers this in error state
    let error = newNumAnswers >= question.minAnswers && newNumAnswers <= question.maxAnswers;
    onValidate({ [question.id]: error });
    setNumAnswers(newNumAnswers);
  };

  return (
    <Card spacing={spacing} sx={{ p: 2, m: 1 }}>
      <Typography variant="h3" sx={{ my: 1 }}>
        {question.text}
      </Typography>
      <FormGroup>
        {answers.map((answer, index) =>
          answer.freeAnswer ? (
            <FreeAnswerInput
              key={`${question.id}-${index}`}
              answerKey={`${question.id}-${index}`}
              answerText={answer.text}
              onChange={validate}
            />
          ) : (
            <CheckboxAnswerInput
              key={`${question.id}-${index}`}
              answerKey={`${question.id}-${index}`}
              answerText={answer.text}
              onChange={validate}
            />
          )
        )}
      </FormGroup>
      {numAnswers < question.minAnswers && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          Seleziona...
        </Alert>
      )}
      {numAnswers >= question.minAnswers && numAnswers <= question.maxAnswers && (
        <Alert severity="success" sx={{ mt: 1 }}>
          Ok
        </Alert>
      )}
      {numAnswers > question.maxAnswers && (
        <Alert severity="error" sx={{ mt: 1 }}>
          Puoi selezionare un massimo di {question.maxAnswers} rispost{question.maxAnswers === 1 ? 'a' : 'e'}.
        </Alert>
      )}
    </Card>
  );
};

SimpleQuestionInput.propTypes = {
  question: PropTypes.object.isRequired
};

export default SimpleQuestionInput;
