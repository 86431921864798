import {
  //DashboardOutlined,
  FlagOutlined,
  //NotificationOutlined,
  TableOutlined,
  UserOutlined,
  BankOutlined,
  ApartmentOutlined,
  FullscreenExitOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  CheckSquareOutlined,
  QuestionOutlined,
  FormOutlined,
  SnippetsOutlined,
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined
} from '@ant-design/icons';

const config = [
  {
    id: 'control-panel',
    title: 'ra.menu.control-panel',
    icon: TableOutlined,
    type: 'group',
    children: [
      {
        id: 'entities/i18n-message',
        title: 'ra.menu.item.entities/i18n-message',
        type: 'item',
        url: '/entities/i18n-message',
        icon: FlagOutlined,
        roles: ['ROLE_ADMIN']
      },
      {
        id: 'entities/user',
        title: 'ra.menu.item.entities/user',
        type: 'item',
        url: '/entities/user',
        icon: UserOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      },
      {
        id: 'entities/schoolType',
        title: 'ra.menu.item.entities/schoolType',
        type: 'item',
        url: '/entities/schoolType',
        icon: ApartmentOutlined,
        roles: ['ROLE_ADMIN']
      },
      {
        id: 'entities/criticalityCategory',
        title: 'ra.menu.item.entities/criticalityCategory',
        type: 'item',
        url: '/entities/criticalityCategory',
        icon: FullscreenExitOutlined,
        roles: ['ROLE_ADMIN']
      },
      {
        id: 'entities/interventionCategory',
        title: 'ra.menu.item.entities/interventionCategory',
        type: 'item',
        url: '/entities/interventionCategory',
        icon: FullscreenOutlined,
        roles: ['ROLE_ADMIN']
      }
    ]
  },
  {
    id: 'anagraphics',
    title: 'ra.menu.anagraphic-pages',
    icon: TableOutlined,
    type: 'group',
    children: [
      {
        id: 'entities/school',
        title: 'ra.menu.item.entities/school',
        type: 'item',
        url: '/entities/school',
        icon: BankOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      },
      {
        id: 'entities/criticality',
        title: 'ra.menu.item.entities/criticality',
        type: 'item',
        url: '/entities/criticality',
        icon: ExclamationCircleOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      },
      {
        id: 'entities/intervention',
        title: 'ra.menu.item.entities/intervention',
        type: 'item',
        url: '/entities/intervention',
        icon: CheckSquareOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      }
    ]
  },
  {
    id: 'campaign-pages',
    title: 'ra.menu.campaign-pages',
    icon: TableOutlined,
    type: 'group',
    children: [
      {
        id: 'entities/question',
        title: 'ra.menu.item.entities/question',
        type: 'item',
        url: '/entities/question',
        icon: QuestionOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      },
      {
        id: 'entities/questionnaire',
        title: 'ra.menu.item.entities/questionnaire',
        type: 'item',
        url: '/entities/questionnaire',
        icon: SnippetsOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER']
      },
      {
        id: 'entities/campaign',
        title: 'ra.menu.item.entities/campaign',
        type: 'item',
        url: '/entities/campaign',
        icon: FormOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER', 'ROLE_MANAGER', 'ROLE_USER']
      }
    ]
  },
  {
    id: 'report-pages',
    title: 'ra.menu.report_pages',
    icon: TableOutlined,
    type: 'group',
    children: [
      {
        id: 'report-chart',
        title: 'ra.menu.item.report_chart',
        type: 'item',
        url: '/report-chart',
        resource: false,
        icon: BarChartOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER', 'ROLE_MANAGER']
      },
      {
        id: 'report-map',
        title: 'ra.menu.item.report_map',
        type: 'item',
        url: '/report-map',
        resource: false,
        icon: DotChartOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER', 'ROLE_MANAGER']
      },
      {
        id: 'report-intervention',
        title: 'ra.menu.item.report_intervention',
        type: 'item',
        url: '/report-intervention',
        resource: false,
        icon: LineChartOutlined,
        roles: ['ROLE_ADMIN', 'ROLE_AREA_MANAGER', 'ROLE_MANAGER']
      }
    ]
  }
];

export default config;
