import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

const AuthBackground = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const color = isDark ? theme.palette.primary.dark : theme.palette.primary.light;
  return (
    <Box sx={{ position: 'absolute', filter: 'blur(20px)', bottom: 0, p: 4, pb: 8 }}>
      <svg width="100%" height="calc(100vh - 175px)" viewBox="0 0 117 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.7523 21.9601L14.0526 17.2379H4.6534L2.95369 21.9601H0L7.78722 0.903564H10.9187L18.706 21.9601H15.7523ZM5.60948 14.6207H13.0988L9.33912 4.24262L5.60948 14.6207Z"
          fill={color}
        />
        <path
          d="M33.592 8.8437C35.0538 10.3782 35.7997 12.2433 35.7997 14.4391C35.7997 16.6348 35.0538 18.5302 33.592 20.0647C32.1301 21.5992 30.3404 22.3513 28.2504 22.3513C25.7747 22.3513 23.8949 21.3593 22.6109 19.3731V27.9769H20.0151V6.91804H22.6109V9.505C23.8949 7.51879 25.7724 6.52686 28.2504 6.52686C30.3381 6.52686 32.1301 7.30923 33.592 8.8437ZM31.6821 18.2881C32.6959 17.2356 33.204 15.9712 33.204 14.4367C33.204 12.9023 32.6959 11.6402 31.6821 10.6157C30.6683 9.56321 29.4143 9.05094 27.9224 9.05094C26.4306 9.05094 25.1489 9.56321 24.1328 10.6157C23.1189 11.6379 22.6109 12.9023 22.6109 14.4367C22.6109 15.9712 23.1189 17.2333 24.1328 18.2881C25.1466 19.3103 26.4006 19.8225 27.9224 19.8225C29.4443 19.8225 30.6683 19.3103 31.6821 18.2881Z"
          fill={color}
        />
        <path
          d="M51.5798 8.8437C53.0416 10.3782 53.7875 12.2433 53.7875 14.4391C53.7875 16.6348 53.0416 18.5302 51.5798 20.0647C50.1179 21.5992 48.3282 22.3513 46.2382 22.3513C43.7625 22.3513 41.8827 21.3593 40.5987 19.3731V27.9769H38.0029V6.91804H40.5987V9.505C41.8827 7.51879 43.7602 6.52686 46.2382 6.52686C48.3258 6.52686 50.1179 7.30923 51.5798 8.8437ZM49.6699 18.2881C50.6837 17.2356 51.1918 15.9712 51.1918 14.4367C51.1918 12.9023 50.6837 11.6402 49.6699 10.6157C48.6561 9.56321 47.4021 9.05094 45.9102 9.05094C44.4184 9.05094 43.1367 9.56321 42.1206 10.6157C41.1067 11.6379 40.5987 12.9023 40.5987 14.4367C40.5987 15.9712 41.1067 17.2333 42.1206 18.2881C43.1344 19.3103 44.3884 19.8225 45.9102 19.8225C47.4321 19.8225 48.6561 19.3103 49.6699 18.2881Z"
          fill={color}
        />
        <path d="M55.5449 21.96V0H58.1407V21.96H55.5449Z" fill={color} />
        <path
          d="M60.3188 2.43569C60.3188 1.47402 61.0948 0.661377 62.0786 0.661377C63.0624 0.661377 63.8083 1.47402 63.8083 2.43569C63.8083 3.39736 63.0324 4.17973 62.0786 4.17973C61.1248 4.17973 60.3188 3.39736 60.3188 2.43569ZM60.7669 21.9578V6.91803H63.3626V21.9578H60.7669Z"
          fill={color}
        />
        <path
          d="M67.8354 20.095C66.3436 18.5605 65.5977 16.6954 65.5977 14.4391C65.5977 12.1828 66.3436 10.3176 67.8354 8.81343C69.3273 7.27896 71.2071 6.52686 73.4449 6.52686C76.3986 6.52686 78.8743 8.06133 80.0382 10.4667L77.8605 11.731C77.1145 10.136 75.4425 9.08354 73.4449 9.08354C71.9531 9.08354 70.6991 9.59581 69.6853 10.618C68.7015 11.6402 68.1934 12.9046 68.1934 14.4391C68.1934 15.9735 68.7015 17.2076 69.6853 18.2299C70.6991 19.2521 71.9531 19.7643 73.4449 19.7643C75.4448 19.7643 77.1145 18.7421 77.9505 17.1471L80.1583 18.4394C78.8766 20.8168 76.3386 22.349 73.4449 22.349C71.2071 22.349 69.3273 21.5969 67.8354 20.0927V20.095Z"
          fill={color}
        />
        <path
          d="M94.2686 6.91793H96.8643V21.9577H94.2686V19.3707C92.9869 21.3569 91.107 22.3488 88.6314 22.3488C86.5437 22.3488 84.7516 21.5967 83.2898 20.0623C81.828 18.5278 81.082 16.6324 81.082 14.4366C81.082 12.2409 81.828 10.3757 83.2898 8.84126C84.7516 7.30679 86.5414 6.52441 88.6314 6.52441C91.107 6.52441 92.9869 7.51635 94.2686 9.50256V6.9156V6.91793ZM92.7467 18.288C93.7605 17.2355 94.2686 15.9711 94.2686 14.4366C94.2686 12.9022 93.7605 11.6401 92.7467 10.6156C91.7329 9.5631 90.4789 9.05083 88.957 9.05083C87.4351 9.05083 86.2112 9.5631 85.1973 10.6156C84.1835 11.6378 83.6755 12.9022 83.6755 14.4366C83.6755 15.9711 84.1835 17.2331 85.1973 18.288C86.2112 19.3102 87.4652 19.8224 88.957 19.8224C90.4489 19.8224 91.7306 19.3102 92.7467 18.288Z"
          fill={color}
        />
        <path
          d="M0.836162 48.9264L3.22406 47.5432C4.03003 50.0999 5.90986 51.3643 8.80351 51.3643C11.6972 51.3643 13.219 50.072 13.219 48.116C13.219 47.1241 12.8311 46.372 12.0851 45.8597C11.3392 45.3475 10.0575 44.8375 8.29545 44.2647L6.29553 43.6034C5.84751 43.4218 5.25169 43.1517 4.56581 42.8211C3.87992 42.4601 3.37186 42.0992 3.04393 41.7383C2.35804 40.9862 1.72989 39.7521 1.72989 38.22C1.72989 36.3851 2.35573 34.9414 3.63975 33.889C4.92376 32.8062 6.47335 32.2637 8.29314 32.2637C11.6048 32.2637 14.1405 34.0985 15.3645 36.8648L13.0366 38.2176C12.1406 36.0521 10.561 34.9694 8.29314 34.9694C6.02533 34.9694 4.50345 36.1732 4.50345 38.0989C4.50345 39.0303 4.83139 39.7242 5.48725 40.2038C6.14311 40.6858 7.27702 41.1958 8.88896 41.708L10.1129 42.0992C10.3508 42.19 10.7088 42.3088 11.2168 42.5207C12.2607 42.9119 12.5008 43.0609 13.3045 43.5126C14.1405 43.9643 14.4084 44.295 14.9164 44.8957C15.6624 45.6478 15.9603 46.7306 15.9603 48.0532C15.9603 49.888 15.3044 51.362 13.9604 52.475C12.6186 53.5577 10.8866 54.1003 8.73885 54.1003C4.83139 54.1003 1.93773 52.1443 0.831543 48.9264H0.836162Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M25.089 54.1001C22.9112 54.1001 21.0314 53.348 19.5095 51.8135C17.9876 50.279 17.2417 48.4139 17.2417 46.1878C17.2417 43.9618 17.9876 42.0967 19.5095 40.5622C21.0314 39.0277 22.9112 38.2756 25.089 38.2756C27.2667 38.2756 29.1465 39.0277 30.6684 40.5622C32.1903 42.0967 32.9663 43.9618 32.9663 46.1878C32.9663 48.4139 32.1903 50.279 30.6684 51.8135C29.1465 53.348 27.2667 54.1001 25.089 54.1001ZM28.8486 50.0089C29.8624 48.9867 30.3705 47.7223 30.3705 46.1878C30.3705 44.6534 29.8624 43.3913 28.8486 42.3668C27.8348 41.3446 26.5808 40.8323 25.089 40.8323C23.5971 40.8323 22.3731 41.3446 21.3593 42.3668C20.3455 43.389 19.8374 44.6534 19.8374 46.1878C19.8374 47.7223 20.3455 48.9844 21.3593 50.0089C22.3731 51.0311 23.6271 51.5434 25.089 51.5434C26.5508 51.5434 27.8348 51.0311 28.8486 50.0089Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M42.5409 34.7877C39.9151 34.5782 38.6034 35.7215 38.6034 38.2176V38.6693H42.5409V41.1958H38.6034V53.7091H36.0077V41.1958H33.6221V38.6693H36.0077V38.2176C36.0077 34.1567 38.3355 31.9912 42.5409 32.2613V34.7877Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M52.7718 41.1958H48.8043V49.3478C48.8043 51.6647 50.116 51.4831 52.7718 51.3643V53.7114C48.2362 54.3122 46.2086 53.0804 46.2086 49.3502V41.1981H43.2549V38.6717H46.2086V35.2418L48.8043 34.4595V38.6717H52.7718V41.1981V41.1958Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M72.9095 38.6692H75.6254L71.002 53.7089H68.3462L64.8544 42.5787L61.3627 53.7089H58.7069L54.0835 38.6692H56.797L60.0786 50.0998L63.5981 38.6692H66.1038L69.6233 50.13L72.9049 38.6692H72.9095Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M88.5694 38.6692H91.1651V53.7089H88.5694V51.1219C87.2876 53.1081 85.4078 54.1001 82.9322 54.1001C80.8445 54.1001 79.0524 53.348 77.5906 51.8135C76.1287 50.279 75.3828 48.3836 75.3828 46.1878C75.3828 43.9921 76.1287 42.127 77.5906 40.5925C79.0524 39.058 80.8422 38.2756 82.9322 38.2756C85.4078 38.2756 87.2876 39.2676 88.5694 41.2538V38.6668V38.6692ZM87.0475 50.0392C88.0613 48.9867 88.5694 47.7223 88.5694 46.1878C88.5694 44.6534 88.0613 43.3913 87.0475 42.3668C86.0337 41.3143 84.7797 40.802 83.2578 40.802C81.7359 40.802 80.5119 41.3143 79.4981 42.3668C78.4843 43.389 77.9762 44.6534 77.9762 46.1878C77.9762 47.7223 78.4843 48.9844 79.4981 50.0392C80.5119 51.0614 81.7659 51.5737 83.2578 51.5737C84.7496 51.5737 86.0313 51.0614 87.0475 50.0392Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M96.8023 41.1957C97.6683 39.3306 99.2202 38.3992 101.456 38.3992V41.1375C100.172 41.0769 99.0978 41.4076 98.1741 42.1597C97.2503 42.9118 96.8023 44.0853 96.8023 45.7386V53.709H94.2065V38.6693H96.8023V41.1957Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M104.409 47.3918C104.917 50.0696 106.975 51.6343 109.809 51.6343C111.778 51.6343 113.21 50.9125 114.104 49.4385L116.312 50.7029C114.85 52.9592 112.672 54.1025 109.748 54.1025C107.391 54.1025 105.453 53.3504 103.961 51.8765C102.499 50.3723 101.753 48.4769 101.753 46.1903C101.753 43.9037 102.469 42.0386 103.931 40.5344C105.393 39.0302 107.273 38.2781 109.568 38.2781C111.746 38.2781 113.536 39.0604 114.91 40.6252C116.312 42.1597 116.998 44.0248 116.998 46.2206C116.998 46.6117 116.968 47.0029 116.908 47.3941H104.407L104.409 47.3918ZM104.409 45.1052H114.374C113.926 42.2482 111.956 40.7439 109.571 40.7439C106.797 40.7439 104.857 42.4577 104.409 45.1052Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M22.1377 69.1726V70.4369C22.1377 73.1752 21.2116 75.4595 19.3341 77.2943C17.4542 79.0989 14.9786 80.0024 11.9048 80.0024C8.83102 80.0024 6.08748 78.9499 3.99749 76.8449C1.93983 74.7097 0.895996 72.1228 0.895996 69.0841C0.895996 66.0454 1.93983 63.4584 3.99749 61.3535C6.08517 59.2182 8.71093 58.1658 11.8448 58.1658C13.7846 58.1658 15.5444 58.6478 17.1563 59.5792C18.7683 60.5106 20.02 61.7144 20.886 63.2489L18.4981 64.6623C17.3041 62.3757 14.7084 60.8715 11.8147 60.8715C9.42684 60.8715 7.45925 61.6539 5.93737 63.2489C4.41549 64.8136 3.63954 66.7695 3.63954 69.0841C3.63954 71.3986 4.41549 73.3545 5.93737 74.9193C7.48927 76.484 9.48688 77.2664 11.9348 77.2664C16.1102 77.2664 18.796 74.9798 19.3641 71.7013H11.697V69.1749H22.14L22.1377 69.1726Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M34.636 64.5691H37.2317V79.6088H34.636V77.4433C33.5921 79.1571 31.9802 80 29.8024 80C26.3107 80 24.0151 77.6226 24.0151 73.8039V64.5691H26.6109V73.6525C26.6109 76.0881 28.0127 77.5039 30.3105 77.5039C32.9062 77.5039 34.636 75.9089 34.636 72.5395V64.5691Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M42.4209 67.0956C43.287 65.2305 44.8389 64.2991 47.0743 64.2991V67.0374C45.7903 66.9768 44.7165 67.3075 43.7927 68.0596C42.869 68.8117 42.4209 69.9852 42.4209 71.6385V79.6089H39.8252V64.5692H42.4209V67.0956Z"
          fill={color}
          opacity="0.6"
        />
        <path
          d="M59.3049 64.5691H61.9007V79.6088H59.3049V77.4433C58.2611 79.1571 56.6491 80 54.4714 80C50.9796 80 48.6841 77.6226 48.6841 73.8039V64.5691H51.2798V73.6525C51.2798 76.0881 52.6816 77.5039 54.9794 77.5039C57.5752 77.5039 59.3049 75.9089 59.3049 72.5395V64.5691Z"
          fill={color}
          opacity="0.6"
        />
      </svg>
    </Box>
  );
};

export default AuthBackground;
