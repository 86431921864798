import React, { useState } from 'react';
import { Stack, Card, Grid, Typography } from '@mui/material';
import { useThemeConfig, Form, useDataProvider } from '@applica-software-guru/react-admin';
import ChartFilterBar from './ChartFilterBar';
import BasicBarChart from './BasicBarChart';
import downloadFile from '../../utils/downloadFile';

const ChartReportPage = () => {
  const { spacing } = useThemeConfig();
  const dataProvider = useDataProvider();
  const [questions, setQuestions] = useState(null);
  const [reportData, setReportData] = useState(null);

  const loadReportData = (filters) => {
    const load = async (f) => {
      const params = {};

      // this filter is necessary
      if (typeof f.filters.campaignId !== 'undefined' && f.filters.campaignId !== null) {
        params.campaignId = f.filters.campaignId;
      }

      if (f.filters.schoolIds !== undefined && f.filters.schoolIds !== null) {
        params.schoolIds = f.filters.schoolIds.join(',');
      }

      const questionsResponse = await dataProvider.get('question', { campaignId: f.filters.campaignId });
      const questionRows = questionsResponse.data.value;

      const response = await dataProvider.get('report/chart', params);
      const reportRows = response.data.value;

      // riordino dati in formato chart

      const reportDataByQuestionId = {};

      // init empty arrays

      questionRows.forEach((el) => {
        reportDataByQuestionId[el.id] = { title: el.text, series: [], categories: [] };
        const isRadioQuestion = el.answerLabels.length >= 1;
        if (isRadioQuestion) {
          // multiple series for radio questions
          for (var i = 0; i < el.answers.length; i++) {
            let a = new Array(el.answerLabels.length);
            a.fill(0);
            reportDataByQuestionId[el.id].series.push({ name: el.answers[i].text, data: a });
          }
          for (var j = 0; j < el.answerLabels.length; j++) {
            const answerLabel = el.answerLabels[j];
            // don't put null in categories, it breaks the whole chart
            if (answerLabel !== null) {
              reportDataByQuestionId[el.id].categories.push(answerLabel);
            } else {
              reportDataByQuestionId[el.id].categories.push('');
            }
          }
        } else {
          // single series for checkbox questions
          let a = new Array(el.answers.length);
          a.fill(0);
          reportDataByQuestionId[el.id].series.push({ data: a });

          for (var k = 0; k < el.answers.length; k++) {
            reportDataByQuestionId[el.id].categories.push(el.answers[k].text);
          }
        }
      });

      // put count values in chart data arrays

      reportRows.forEach((row) => {
        const questionId = row.qid;
        const answerIndex = row.aid;
        const count = row.count;
        const value = row.val;
        const isRadio = typeof value !== 'undefined';
        if (isRadio) {
          reportDataByQuestionId[questionId].series[answerIndex].data[value] = count;
        } else {
          reportDataByQuestionId[questionId].series[0].data[answerIndex] = count;
        }
      });

      setReportData(reportDataByQuestionId);
      setQuestions(questionRows);
    };

    load(filters);
  };

  const exportReportData = (filters) => {
    const download = async (f) => {
      const params = {};

      // this filter is necessary
      if (typeof f.filters.campaignId !== 'undefined' && f.filters.campaignId !== null) {
        params.campaignId = f.filters.campaignId;
      }

      if (f.filters.schoolIds !== undefined && f.filters.schoolIds !== null) {
        params.schoolIds = f.filters.schoolIds;
      }

      const response = await dataProvider.get('report/export-excel', params);
      const excelData = response.data.value;
      const now = new Date();
      const timestamp = now.valueOf();
      downloadFile(excelData, `Mobilityamoci-export-${timestamp}.xlsx`);
    };

    download(filters);
  };

  return (
    <div>
      <Stack direction="column" spacing={spacing}>
        <Card>
          <Form>
            <ChartFilterBar
              onButtonClick={(newFilters) => loadReportData(newFilters)}
              onExportClick={(newFilters) => exportReportData(newFilters)}
              showExport={reportData !== null}
            />
          </Form>
        </Card>
        <Grid container>
          {questions !== null ? (
            questions.map((question) => {
              if (reportData === null) {
                return <div key={question.id}></div>;
              }

              return (
                <Grid item xs={12} lg={6} key={question.id}>
                  <Card sx={{ m: 2, p: 1 }}>
                    <Typography>{reportData[question.id].title}</Typography>
                    <BasicBarChart series={reportData[question.id].series} categories={reportData[question.id].categories} />
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Typography>Please load data.</Typography>
          )}
        </Grid>
      </Stack>
    </div>
  );
};

export default ChartReportPage;
