import { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { LabeledInput, useDataProvider, useInput } from '@applica-software-guru/react-admin';

/*
 * TODO: CRITICAL BUG
 *
 * FIX INFINITE RENDER LOOP
 *
 */

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}
// TODO: scrivere logica che gestisce il tasto tutti.
// Per evitare glitch grafici, il valore sara 'all' e verrà gestito lato server evitando di applicare il filtro scuola

const SchoolArrayInputAllV2 = (props) => {
  const { campaignId, ...inputProps } = props;
  const { field } = useInput({ ...inputProps, defaultValue: ['all'] });

  const [schools, setSchools] = useState([]);
  const [schoolIds, setSchoolIds] = useState(field.value);

  const theme = useTheme();
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function fetchData() {
      let response = null;
      let responseData = [];
      if (campaignId) {
        response = await dataProvider.get(`campaign/${campaignId}/schools`, {});
        responseData = response.data;
      } else {
        response = await dataProvider.getList('entities/school', {});
        responseData = response.data;
      }

      setSchools(responseData);
    }
    fetchData();
  }, [dataProvider, setSchools, campaignId]);

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      if (value[value.length - 1] === 'all') {
        const newValue = ['all'];
        setSchoolIds(newValue);
        field.onChange(newValue);
        return;
      } else {
        const allPosition = value.indexOf('all');
        if (allPosition >= 0) {
          value.splice(allPosition, 1);
        }
        setSchoolIds(value);
        field.onChange(value);
      }
    },
    [setSchoolIds, field]
  );

  // TODO: FIX INFINITE RENDER LOOP - use console.log to detect
  console.log('debug - SchoolArrayInputAllV2 - render');

  return (
    <LabeledInput name="school-array" label="Istituti">
      <Select multiple value={field.value} input={<OutlinedInput label="School" />} MenuProps={MenuProps} onChange={handleChange}>
        <MenuItem key="all" value="all">
          Tutti
        </MenuItem>
        {schools.map((school) => (
          <MenuItem key={school.id} value={school.id} style={getStyles(school, schoolIds, theme)}>
            {school.name}
          </MenuItem>
        ))}
      </Select>
    </LabeledInput>
  );
};

export default SchoolArrayInputAllV2;
