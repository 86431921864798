import { Create, Edit } from '@applica-software-guru/react-admin';
import { SchoolForm, SchoolList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const SchoolCreate = () => (
  <Create>
    <SchoolForm />
  </Create>
);

const SchoolEdit = () => (
  <Edit>
    <SchoolForm />
  </Edit>
);

const config = {
  list: SchoolList,
  edit: SchoolEdit,
  create: SchoolCreate,
  options: {
    group: 'anagraphics',
    icon: FlagOutlined
  }
};

export default config;
