import { useEffect, useState } from 'react';

import useApplicaAuthProvider from './useApplicaAuthProvider';

/**
 * Recupera i ruoli dell'utente corrente.
 *
 * @returns {{isLoading: boolean, roles: string[]}}
 */
const useGetRoles = () => {
  const applicaAuthProvider = useApplicaAuthProvider();
  const [data, setData] = useState({ isLoading: true, roles: [] });
  useEffect(() => {
    setData({ isLoading: true, roles: [] });
    applicaAuthProvider.getRoles().then((roles) => {
      // Temp workaround(JSON.parse()) was removed because roles suddenly became unescaped.
      // TODO: check reason why behaviour changed
      setData({ isLoading: false, roles: roles });
    });
  }, [applicaAuthProvider]);
  return data;
};

export default useGetRoles;
