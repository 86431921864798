import { SelectInput } from '@applica-software-guru/react-admin';
const CampaignStateField = (props) => (
  <SelectInput
    {...props}
    choices={[
      { id: 'DRAFT', name: 'Bozza' },
      { id: 'CONFIRMED', name: 'Confermata' },
      { id: 'ONGOING', name: 'In corso', disabled: true },
      { id: 'COMPLETED', name: 'Completata', disabled: true }
    ]}
  />
);

export default CampaignStateField;
