import { SelectArrayInput } from '@applica-software-guru/react-admin';

const CheckboxAnswerInput = ({ name, onChange, answers, defaultValue }) => (
  <SelectArrayInput
    name={name}
    onChange={onChange}
    source="activeAnswers"
    label="Risposte visibili"
    choices={answers}
    defaultValue={defaultValue}
    disabled={answers === undefined || answers === null || answers.length < 1}
  />
);

export default CheckboxAnswerInput;
