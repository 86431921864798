import { BooleanField, Datagrid, List, SearchInput, TextField, useThemeConfig, useTranslate } from '@applica-software-guru/react-admin';

import { Alert } from '@mui/material';

const AlertBox = () => {
  const { spacing } = useThemeConfig();
  const translate = useTranslate();
  return (
    <Alert sx={{ p: spacing, m: spacing }} severity="info">
      {translate('resources.entities/criticality.alert')}
    </Alert>
  );
};
const CriticalityList = () => (
  <List perPage={25} filters={[<SearchInput source="keyword" alwaysOn />]}>
    <AlertBox />
    <Datagrid rowClick="edit">
      <TextField source="description" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export default CriticalityList;
