import { ReferenceInput, SelectInput } from '@applica-software-guru/react-admin';

const QuestionInput = ({ campaignId, name, label, source }) => (
  <ReferenceInput
    name={name}
    label={label}
    source={source}
    reference="entities/question"
    filter={{ campaignId: campaignId ? campaignId : 'placeholder' }}
  >
    <SelectInput optionText={(record) => (record.text.length > 64 ? record.text.substring(0, 61) + '...' : record.text)} />
  </ReferenceInput>
);

export default QuestionInput;
