import { AutocompleteInput } from 'react-admin';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounce } from 'hooks';

const search = async (address) => {
  const key = 'AIzaSyAPejigDGwtRlIki7VD3H3rUwpjozm522M';
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`;

  const response = await fetch(url, { method: 'GET' });
  const json = await response.json();
  return json.results;
};

const MapSearchInput = ({ onChange, ...props }) => {
  const [choices, setChoices] = useState([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (!debouncedFilter || debouncedFilter.length < 3) {
      return;
    }
    async function fetchData() {
      const results = await search(debouncedFilter);
      setChoices(
        results.map((r) => ({
          id: r.place_id,
          ...r
        }))
      );
    }
    fetchData();
  }, [debouncedFilter]);

  const handleChange = useCallback(
    (id) => {
      const target = choices.find((c) => c.id === id);
      if (target && onChange) {
        onChange(target);
      }
    },
    [choices, onChange]
  );
  const handleMatchSuggestion = useCallback(() => true, []);

  return (
    <AutocompleteInput
      {...props}
      label={props.label}
      source="__keyword__"
      choices={choices}
      optionText={'formatted_address'}
      optionValue={'id'}
      setFilter={setFilter}
      matchSuggestion={handleMatchSuggestion}
      onChange={handleChange}
    />
  );
};

export default MapSearchInput;
