import { SelectInput } from '@applica-software-guru/react-admin';
const QuestionTypeSelectInput = (props) => (
  <SelectInput
    {...props}
    choices={[
      { id: 'CURRENT_STATE', name: 'Stato corrente' },
      { id: 'PROPENSION', name: 'Propensione' }
    ]}
  />
);

export default QuestionTypeSelectInput;
