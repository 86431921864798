import { NotificationList as BaseNotificationList, List } from '@applica-software-guru/react-admin';

import { MessageOutlined } from '@ant-design/icons';
import React from 'react';

const NotificationList = () => (
  <List>
    <BaseNotificationList />
  </List>
);
const config = {
  list: NotificationList,
  options: {
    icon: MessageOutlined,
    group: 'dashboard'
  }
};
export default config;
