import '@applica-software-guru/react-admin/style.css';

import * as entities from './entities';

import { API_URL, APP_NAME, FILE_FIELDS } from './config';
import { ActivatePage, ApplicaAdmin, HttpError, RecoverPage, Resource } from '@applica-software-guru/react-admin';
import { createAttachmentsParser, createDataProvider } from '@applica-software-guru/crud-client';

import { SubmissionPage, SubmissionViewPage, ChartReportPage, MapReportPage, InterventionReportPage, HomePage } from 'components/pages';
import { CustomRegisterPage } from './vendor';
import { CustomRoutes } from 'ra-core';
import { Route } from 'react-router-dom';
import build from './build.json';
import { createAuthProvider } from '@applica-software-guru/iam-client';
import menu from './menu';
import theme from './theme';

const authProvider = createAuthProvider({ apiUrl: API_URL });
const dataProvider = createDataProvider({
  apiUrl: API_URL,
  getHeaders: () => authProvider.getHeaders(),
  getToken: () => authProvider.getToken(),
  attachmentsParser: createAttachmentsParser(FILE_FIELDS),
  HttpErrorClass: HttpError
});
const App = () => {
  return (
    <ApplicaAdmin
      theme={theme}
      apiUrl={API_URL}
      authProvider={authProvider}
      dataProvider={dataProvider}
      defaultLocale="it"
      menu={menu}
      name={APP_NAME}
      dashboard={HomePage}
      version={build.version}
      enableRegistration
      enablePasswordRecover
    >
      <Resource name="entities/notification" {...entities.notification} />
      <Resource name="entities/user" {...entities.user} />
      <Resource name="entities/i18n-message" {...entities.i18nMessage} />
      <Resource name="entities/device" {...entities.device} />
      <Resource name="entities/schoolType" {...entities.schoolType} />
      <Resource name="entities/school" {...entities.school} />
      <Resource name="entities/interventionCategory" {...entities.interventionCategory} />
      <Resource name="entities/intervention" {...entities.intervention} />
      <Resource name="entities/criticalityCategory" {...entities.criticalityCategory} />
      <Resource name="entities/criticality" {...entities.criticality} />
      <Resource name="entities/question" {...entities.question} />
      <Resource name="entities/questionnaire" {...entities.questionnaire} />
      <Resource name="entities/campaign" {...entities.campaign} />
      <CustomRoutes>
        <Route path="/submission-page/:campaignId/:questionnaireId" element={<SubmissionPage />} />
        <Route path="/submission-view-page/:campaignId" element={<SubmissionViewPage />} />
        <Route path="/report-chart" element={<ChartReportPage />} />
        <Route path="/report-map" element={<MapReportPage />} />
        <Route path="/report-intervention" element={<InterventionReportPage />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/register" element={<CustomRegisterPage name={APP_NAME} version={build.version} />} />
        <Route path="/recover" element={<RecoverPage name={APP_NAME} version={build.version} />} />
        <Route path="/activate/:token" element={<ActivatePage name={APP_NAME} version={build.version} />} />
      </CustomRoutes>
    </ApplicaAdmin>
  );
};

export default App;
