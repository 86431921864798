import PropTypes from 'prop-types';
import React from 'react';
import CheckboxAnswerField from './CheckboxAnswerField';
import FreeAnswerField from './FreeAnswerField';
import { FormGroup, Card, Typography } from '@mui/material';
import { useThemeConfig } from '@applica-software-guru/react-admin';

const SimpleQuestionField = (props) => {
  // TODO: scrivere la logica che renderizza gli input base coi valori corretti
  const { spacing } = useThemeConfig();

  const question = props.question;
  const entries = props.entries;
  const answers = question.answers;

  return (
    <Card spacing={spacing} sx={{ p: 2, m: 1 }}>
      <Typography variant="h3" sx={{ my: 1 }}>
        {question.text}
      </Typography>
      <FormGroup>
        {answers.map((answer, index) => {
          // find relevant entry and pass values if present
          const relevantEntry = entries.find((el) => el.answerIndex === index);
          const checked = relevantEntry !== undefined;
          const answerValue = relevantEntry !== undefined ? relevantEntry.value : undefined;

          return answer.freeAnswer ? (
            <FreeAnswerField
              key={`${question.id}-${index}`}
              answerKey={`${question.id}-${index}`}
              answerText={answer.text}
              checked={checked}
              answerValue={answerValue}
            />
          ) : (
            <CheckboxAnswerField
              key={`${question.id}-${index}`}
              answerKey={`${question.id}-${index}`}
              answerText={answer.text}
              checked={checked}
            />
          );
        })}
      </FormGroup>
    </Card>
  );
};

SimpleQuestionField.propTypes = {
  question: PropTypes.object.isRequired
};

export default SimpleQuestionField;
