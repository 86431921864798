import React, { useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Paper, Grid, Button, FormControl, FormHelperText, LinearProgress, Alert } from '@mui/material';
import { SchoolArrayInputAllV2, CampaignSelectInput, InterventionSelectArrayInput } from 'components/ra-inputs';

const canSubmit = (f) => typeof f.campaignId !== 'undefined' && f.campaignId !== null;

const InterventionFilterBar = ({ onButtonClick, onInterventionChange, isLoading, disabledInterventions }) => {
  const filters = useWatch();

  // propagate the updated filters values to parent
  const handleReload = useCallback(() => {
    if (canSubmit(filters)) {
      onButtonClick(filters);
    }
  }, [filters, onButtonClick]);

  const handleInterventionChange = useCallback(
    (e) => {
      onInterventionChange(e.target.value);
    },
    [onInterventionChange]
  );

  useEffect(() => console.log(filters), [filters]);

  return (
    <Paper elevation={24} sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={6} lg={2}>
          <CampaignSelectInput label="Campagna" validation="required" source="campaignId" />
        </Grid>
        <Grid item xs={6} lg={2}>
          <SchoolArrayInputAllV2 label="Istituto" validation="required" source="schoolIds" />
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth>
            <FormHelperText>&nbsp;</FormHelperText>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Button
                color="primary"
                sx={{ mt: 0.5 }}
                fullWidth={true}
                onClick={handleReload}
                variant="contained"
                disabled={!canSubmit(filters)}
              >
                Carica
              </Button>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={3}>
          {!canSubmit(filters) && (
            <Alert severity="info" sx={{ mt: 3 }}>
              Seleziona una Campagna per iniziare.
            </Alert>
          )}
        </Grid>
        <Grid item xs={4} lg={3}>
          <InterventionSelectArrayInput
            label="Simulazione interventi"
            onChange={handleInterventionChange}
            source="interventionIds"
            disabled={disabledInterventions}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

InterventionFilterBar.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default InterventionFilterBar;
