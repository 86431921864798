import {
  Button,
  CreateButton,
  Datagrid,
  Empty,
  List,
  SearchInput,
  TextField,
  FunctionField,
  useDataProvider,
  useRecordContext,
  useThemeConfig,
  useTranslate
} from '@applica-software-guru/react-admin';
import { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router';

import { Alert } from '@mui/material';
import { useGetRoles } from 'hooks';

const AlertBox = () => {
  const { spacing } = useThemeConfig();
  const translate = useTranslate();
  return (
    <Alert sx={{ p: spacing, m: spacing }} severity="info">
      {translate('resources.entities/campaign.alert')}
    </Alert>
  );
};

const CreateSubmissionButton = () => {
  const record = useRecordContext();
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Button sx={{ width: '100%' }} onClick={() => setClicked(true)} variant="contained" label="Partecipa" />
      {clicked && <Navigate to={`/submission-page/${record.id}/${record.questionnaireId}`}></Navigate>}
    </>
  );
};

const ViewSubmissionButton = () => {
  const record = useRecordContext();
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Button sx={{ width: '100%' }} color="success" onClick={() => setClicked(true)} variant="contained" label="Visualizza" />
      {clicked && <Navigate to={`/submission-view-page/${record.id}/`}></Navigate>}
    </>
  );
};

const DraftSubmissionButton = () => {
  // TODO: implement viewing page to link to
  return (
    <>
      <Alert severity="info">Bozza</Alert>
    </>
  );
};

const ConfirmedSubmissionButton = () => {
  // TODO: implement viewing page to link to
  return (
    <>
      <Alert severity="info">Pianificata</Alert>
    </>
  );
};

const ExpiredSubmissionButton = () => {
  // TODO: implement viewing page to link to
  return (
    <>
      <Alert severity="error">Campagna chiusa</Alert>
    </>
  );
};

const EditButton = () => {
  const record = useRecordContext();
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Button sx={{ width: '100%' }} onClick={() => setClicked(true)} variant="contained" label="Modifica" />
      {clicked && <Navigate to={`/entities/campaign/${record.id}/`}></Navigate>}
    </>
  );
};

const CampaignList = () => {
  const { isLoading, roles } = useGetRoles();
  const hasEdit = !isLoading && (roles?.includes('ROLE_ADMIN') || roles?.includes('ROLE_AREA_MANAGER'));
  const [campaignStatus, setCampaignStatus] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const loadCampaignStatus = async () => {
      // query submissions
      const statusResponse = await dataProvider.get(`campaign/status`, {});
      setCampaignStatus(statusResponse.data.value);
    };

    loadCampaignStatus();
  }, [dataProvider, setCampaignStatus]);

  const renderCampaignItem = useCallback(
    (record) => {
      if (typeof campaignStatus === 'undefined') {
        return <span>Undefined campaignStatus!</span>;
      }
      if (campaignStatus === null) {
        return <span>Null campaignStatus!</span>;
      }
      const status = campaignStatus[record.id];
      if (status === 'UNKNOWN') {
        return (
          <>
            <span>Unknown status!</span>
            <CreateSubmissionButton />
          </>
        );
      }
      if (status === 'SUBMITTED') {
        return <ViewSubmissionButton />;
      }
      if (status === 'EXPIRED') {
        return <ExpiredSubmissionButton />;
      }
      if (status === 'ONGOING') {
        return <CreateSubmissionButton />;
      }
      if (status === 'CONFIRMED') {
        return <ConfirmedSubmissionButton />;
      }
      if (status === 'DRAFT') {
        return <DraftSubmissionButton />;
      }
      return <span>Couldn't determine status!</span>;
    },
    [campaignStatus]
  );

  return (
    <List
      perPage={25}
      filters={[<SearchInput source="keyword" alwaysOn />]}
      empty={<Empty actions={hasEdit ? <CreateButton /> : <div></div>} />}
    >
      <AlertBox />
      <Datagrid rowClick={false}>
        {/* TODO: Fix CampaignStateField to be usable also in this list */}
        <TextField source="description" />
        <FunctionField render={renderCampaignItem} />
        {hasEdit && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default CampaignList;
