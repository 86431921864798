import { SelectInput } from '@applica-software-guru/react-admin';

const TopLevelAnswerInput = ({ name, onChange, answers, defaultValue }) => (
  <SelectInput
    name={name}
    onChange={onChange}
    source="activeAnswer"
    label="Risposta"
    choices={answers}
    defaultValue={defaultValue}
    disabled={answers === undefined || answers === null || answers.length < 1}
  />
);

export default TopLevelAnswerInput;
