import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useThemeConfig
} from '@applica-software-guru/react-admin';

import { Grid } from '@mui/material';

const CriticalityForm = () => {
  const { spacing } = useThemeConfig();
  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={4}>
          <TextInput source="description" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReferenceInput reference="entities/criticalityCategory" source="categoryId" optionText="description" fullWidth>
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} lg={4}>
          <BooleanInput source="active" validate={required()} defaultValue={true} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default CriticalityForm;
