import { Typography, Card, Button, Stack, CircularProgress, Alert } from '@mui/material';
import { useParams, Navigate } from 'react-router';
import { Form, useDataProvider } from '@applica-software-guru/react-admin';
import { useEffect, useState, useCallback, useRef } from 'react';
import { LabeledQuestionInput, SimpleQuestionInput } from 'components/ra-inputs';
import { useWatch } from 'react-hook-form';

const SubmissionPage = () => {
  const dataProvider = useDataProvider();
  const urlParams = useParams();
  const campaignId = urlParams.campaignId;
  const questionnaireId = urlParams.questionnaireId;
  const [campaignTitle, setCampaignTitle] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [isSubmitCompleted, setIsSubmitCompleted] = useState(false);

  // note: this a submission-like object, used for storing the internal form state; this is NOT a Submission entity
  const [submission, setSubmission] = useState({});
  const handleSubmissionChange = useCallback((newState) => setSubmission(newState), [setSubmission]);

  const validateSubmission = (updatedSubmission) => {
    const answers = Object.keys(updatedSubmission).filter(
      // false values come from un-checking a previously checked box
      // so they must be pruned because we don't store entries for unchecked boxes
      //&& updatedSubmission[key] !== false
      (key) => typeof updatedSubmission[key] !== 'undefined' && updatedSubmission[key] !== 'checkbox-false'
    );
    const invalidQuestions = questions.reduce((sum, question) => {
      const numberofEntries = answers.reduce((sum, entry) => sum + (entry.startsWith(question.id) ? 1 : 0), 0);
      const isInvalid = numberofEntries < question.minAnswers || numberofEntries > question.maxAnswers ? 1 : 0;
      return sum + isInvalid;
    }, 0);

    return invalidQuestions === 0;
  };

  useEffect(() => {
    const load = async () => {
      const campaignResponse = await dataProvider.get(`entities/campaign/${campaignId}`);
      const questionsResponse = await dataProvider.get(`question`, { campaignId: campaignId });

      setCampaignTitle(campaignResponse.data.value.description);
      setQuestions(questionsResponse.data.value);
    };

    load();
  }, [dataProvider, campaignId, questionnaireId]);

  const handleSubmit = (formData) => console.log(formData);

  const handleSubmitClick = () => {
    const submit = async () => {
      const entries = {};
      const answers = Object.keys(submission).filter(
        // false values come from un-checking a previously checked box
        // so they must be pruned because we don't store entries for unchecked boxes
        (key) => typeof submission[key] !== 'undefined' && submission[key] !== 'checkbox-false'
      );
      answers.forEach((answer) =>
        submission[answer] === 'checkbox-true' ? (entries[answer] = null) : (entries[answer] = submission[answer])
      );

      const payload = { campaignId: campaignId, entries: entries };
      const response = await dataProvider.post(`submission`, payload);
      console.log('submission response: %o', response);
      setShowSubmitLoader(false);
      setIsSubmitCompleted(true); // TODO: only set to true after response check
    };

    setShowSubmitLoader(true);
    submit();
  };

  return questions !== null && campaignTitle !== null ? (
    <Form onSubmit={handleSubmit}>
      {campaignTitle !== null && (
        <Card sx={{ p: 2, m: 1, justifyContent: 'center', display: 'flex' }}>
          <Typography variant="h1">{campaignTitle}</Typography>
        </Card>
      )}

      <SubmissionFormContent questions={questions} onChange={handleSubmissionChange} />
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        {showSubmitLoader ? (
          <CircularProgress sx={{ m: 4 }} />
        ) : isSubmitCompleted ? (
          <>
            <Alert severity="success">Il questionario è stato inviato correttamente.</Alert>
            <Navigate to="/entities/campaign" />
          </>
        ) : (
          <Button onClick={handleSubmitClick} disabled={!validateSubmission(submission)} sx={{ m: 4 }} size="large" variant="contained">
            INVIA
          </Button>
        )}
      </Stack>
    </Form>
  ) : (
    <div>Loading...</div>
  );
};

const SubmissionFormContent = (props) => {
  const questions = props.questions || [];
  const onChange = props.onChange;
  const values = useWatch();

  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    onChange(values);
  }, [values, onChange]);

  return (
    <>
      {questions.map((question) => {
        return question.answerLabels.length === 0 ? (
          <SimpleQuestionInput key={question.id} question={question} />
        ) : (
          <LabeledQuestionInput key={question.id} question={question} horizontal={true} />
        );
      })}
    </>
  );
};

export default SubmissionPage;
