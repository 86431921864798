import React, { useState } from 'react';
import { Stack, Card, Grid, Typography } from '@mui/material';
import { useThemeConfig, Form, useDataProvider } from '@applica-software-guru/react-admin';
import InterventionFilterBar from './InterventionFilterBar';
import BasicBarChart from './BasicBarChart';

const buildCriticalitySeries = (criticalities, reportData) => {
  const result = { series: [{ data: [] }], categories: [] };
  // criticality entity list
  criticalities.forEach((c) => {
    const currValue = reportData.criticalities[c.id];
    if (currValue === undefined) {
      return;
    }
    const currLabel = c.description;
    const roundedValue = parseFloat(currValue.toFixed(2));
    result.series[0].data.push(roundedValue);
    result.categories.push(currLabel);
  });

  return result;
};

const buildPlanSeries = (criticalities, reportData, interventions, activeInterventions) => {
  const result = { series: [{ data: [] }], categories: [] };

  criticalities.forEach((c) => {
    let currValue = reportData.criticalities[c.id];
    const currLabel = c.description;

    result.categories.push(currLabel);

    let interventionScore = 0;
    activeInterventions.forEach((intId) => {
      const currInt = interventions.find((el) => el.id === intId);
      if (currInt.criticalityIds?.indexOf(c.id) > -1) {
        // reduce current criticality point by intervention score.
        // check is intervention score actually exists in this campaignId
        if (typeof reportData.interventions[intId] !== 'undefined' && reportData.interventions[intId] !== null) {
          interventionScore += reportData.interventions[intId];
        }
      }
    });

    const nextValue = Math.max(currValue - interventionScore, 0);
    const roundedValue = parseFloat(nextValue.toFixed(2));

    result.series[0].data.push(roundedValue);
  });

  return result;
};

const InterventionReportPage = () => {
  const { spacing } = useThemeConfig();
  const dataProvider = useDataProvider();
  const [criticalities, setCriticalities] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [activeInterventions, setActiveInterventions] = useState([]);

  const [currentChartData, setCurrentChartData] = useState({ series: [{ data: [] }], categories: [] });
  const [planChartData, setPlanChartData] = useState({ series: [{ data: [] }], categories: [] });

  const loadInterventionData = (filters) => {
    const load = async (f) => {
      const params = {};

      // this filter is necessary
      if (typeof f.campaignId !== 'undefined' && f.campaignId !== null) {
        params.campaignId = f.campaignId;
      }

      if (typeof f.schoolIds !== 'undefined' && f.schoolIds !== null) {
        params.schoolIds = f.schoolIds;
      }

      const associatedResponse = await dataProvider.get('report/plan/criticalities-and-interventions', { campaignId: params.campaignId });
      const criticalitiesRows = associatedResponse.data.value.criticalities;
      const interventionsRows = associatedResponse.data.value.interventions;

      const reportDataResponse = await dataProvider.get('report/plan', params);
      const reportDataRows = reportDataResponse.data.value;

      const critSeries = buildCriticalitySeries(criticalitiesRows, reportDataRows);
      const planSeries = buildPlanSeries(criticalitiesRows, reportDataRows, interventionsRows, activeInterventions);

      setCriticalities(criticalitiesRows);
      setInterventions(interventionsRows);
      setReportData(reportDataRows);
      setCurrentChartData(critSeries);
      setPlanChartData(planSeries);
    };

    load(filters);
  };

  const refreshCharts = (newInterventionIds) => {
    // qui leggo la currentChartData e genero la nuova planChartData e sttandola refresho il secondo grafico.

    const planSeries = buildPlanSeries(criticalities, reportData, interventions, newInterventionIds);
    setActiveInterventions(newInterventionIds);
    setPlanChartData(planSeries);
  };

  return (
    <div>
      <Stack direction="column" spacing={spacing}>
        <Card>
          <Form>
            <InterventionFilterBar
              onButtonClick={(newFilters) => loadInterventionData(newFilters)}
              onInterventionChange={(newInterventionIds) => refreshCharts(newInterventionIds)}
              disableInterventions={reportData !== null}
            />
          </Form>
        </Card>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Card sx={{ mr: 1, p: 1 }}>
              <Typography>Criticità attuali</Typography>
              <BasicBarChart type="bar" series={currentChartData.series} categories={currentChartData.categories} height={380} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card sx={{ ml: 1, p: 1 }}>
              <Typography>Simulazione intervento</Typography>
              <BasicBarChart type="bar" series={planChartData.series} categories={planChartData.categories} height={380} />
            </Card>
          </Grid>
          {/*
          {questions !== null ? (
            questions.map((question) => {
              if (reportData === null) {
                return <div key={question.id}></div>;
              }

              return (
                <Grid item xs={12} lg={6} key={question.id}>
                  <Card sx={{ m: 2, p: 1 }}>
                    <Typography>Criticità attuali</Typography>
                    <BasicBarChart series={reportData[question.id].series} categories={reportData[question.id].categories} />
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Typography>Please load data.</Typography>
          )}
          */}
        </Grid>
      </Stack>
    </div>
  );
};

export default InterventionReportPage;
