import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RadioAnswerInput from './RadioAnswerInput';

import { FormGroup, Typography, Card, Alert } from '@mui/material';
import { useThemeConfig } from '@applica-software-guru/react-admin';

const LabeledQuestionInput = (props) => {
  const { spacing } = useThemeConfig();

  const question = props.question;
  const answers = question.answers;
  const labels = question.answerLabels;

  // tengo un contatore, che viene aumentato di 1 solo la prima volta che un radio è stato selezionato

  const [numAnswers, setNumAnswers] = useState(0);

  return (
    <Card spacing={spacing} sx={{ p: 2, m: 1 }}>
      <Typography variant="h3" sx={{ my: 1 }}>
        {question.text}
      </Typography>
      <FormGroup>
        {answers.map((answer, index) => (
          <RadioAnswerInput
            key={`${question.id}-${index}`}
            answerKey={`${question.id}-${index}`}
            answerText={answer.text}
            answerLabels={labels}
            horizontal={true}
            onFirstChange={() => setNumAnswers(numAnswers + 1)}
          />
        ))}
      </FormGroup>
      {numAnswers < answers.length && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          Seleziona...
        </Alert>
      )}
      {numAnswers === answers.length && (
        <Alert severity="success" sx={{ mt: 1 }}>
          Ok
        </Alert>
      )}
    </Card>
  );
};

LabeledQuestionInput.propTypes = {
  question: PropTypes.object.isRequired
};

export default LabeledQuestionInput;
