import { Box, Grid } from '@mui/material';

import AuthBackground from './AuthBackground';
import AuthCard from './AuthCard';
import { Footer } from './Footer';
import MainIcon from './MainIcon';
import PropTypes from 'prop-types';
import React from 'react';

const AuthWrapper = ({ version, name, copy, children, background = AuthBackground }) => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      {React.isValidElement(background) ? background : React.createElement(background)}
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
          <MainIcon title={name} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <AuthCard>{children}</AuthCard>
            </Grid>
          </Grid>
          <Grid item sx={{ pb: 1 }}>
            <Footer name={name} copy={copy} version={version} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
AuthWrapper.propTypes = {
  children: PropTypes.node,
  version: PropTypes.string,
  name: PropTypes.string,
  copy: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object])
};

export default AuthWrapper;
