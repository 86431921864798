import { Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const MainIcon = ({ title }) => (
  <Stack direction="row">
    <Typography variant="h2" sx={{ fontWeight: 'regular', letterSpacing: -2.5 }}>
      a.
    </Typography>
    &nbsp;
    <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
  </Stack>
);

MainIcon.propTypes = {
  title: PropTypes.string.isRequired
};

export default MainIcon;
