import { Create, Edit } from '@applica-software-guru/react-admin';
import { QuestionnaireForm, QuestionnaireList } from 'components';

import { FormOutlined } from '@ant-design/icons';

const QuestionnaireCreate = () => (
  <Create>
    <QuestionnaireForm />
  </Create>
);

const QuestionnaireEdit = () => (
  <Edit>
    <QuestionnaireForm />
  </Edit>
);

const config = {
  list: QuestionnaireList,
  edit: QuestionnaireEdit,
  create: QuestionnaireCreate,
  options: {
    group: 'campaign-pages',
    icon: FormOutlined
  }
};

export default config;
