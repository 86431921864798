import { FunctionField } from '@applica-software-guru/react-admin';

const QuestionTypeField = (props) => {
  const { source, ...rest } = props;
  const values = {
    CURRENT_STATE: 'Stato corrente',
    PROPENSION: 'Propensione'
  };

  return <FunctionField {...rest} source={source} render={(record) => values[record[source]]} />;
};

export default QuestionTypeField;
