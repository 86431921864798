import { Create, Edit } from '@applica-software-guru/react-admin';
import { SchoolTypeForm, SchoolTypeList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const SchoolTypeCreate = () => (
  <Create>
    <SchoolTypeForm />
  </Create>
);

const SchoolTypeEdit = () => (
  <Edit>
    <SchoolTypeForm />
  </Edit>
);

const config = {
  list: SchoolTypeList,
  edit: SchoolTypeEdit,
  create: SchoolTypeCreate,
  options: {
    group: 'control-panel',
    icon: FlagOutlined
  }
};

export default config;
