import { ActionsField, Datagrid, List, RecordInput, SearchInput, TextField } from '@applica-software-guru/react-admin';

const I18nMessageList = () => (
  <List perPage={25} filters={[<SearchInput key="keyword" source="keyword" alwaysOn />]}>
    <Datagrid>
      <TextField source="lang" />
      <TextField source="code" />
      <RecordInput source="text" />
      <ActionsField />
    </Datagrid>
  </List>
);

export default I18nMessageList;
