import { useAuthProvider } from '@applica-software-guru/react-admin';

/**
 * @returns {import('@applica-software-guru/iam-client/dist/types').ApplicaAuthProviderInterface}
 */
const useApplicaAuthProvider = () => {
  const authProvider = useAuthProvider();
  return authProvider;
};

export default useApplicaAuthProvider;
