import { Create, Edit } from '@applica-software-guru/react-admin';
import { QuestionForm, QuestionList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const QuestionCreate = () => (
  <Create>
    <QuestionForm />
  </Create>
);

const QuestionEdit = () => (
  <Edit>
    <QuestionForm />
  </Edit>
);

const config = {
  list: QuestionList,
  edit: QuestionEdit,
  create: QuestionCreate,
  options: {
    group: 'campaign-pages',
    icon: FlagOutlined
  }
};

export default config;
