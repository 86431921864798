import {
  useThemeConfig,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput
} from '@applica-software-guru/react-admin';
import { Grid } from '@mui/material';

const QuestionnaireForm = () => {
  const { spacing } = useThemeConfig();
  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={9}>
          <TextInput source="title" />
        </Grid>
        <Grid item xs={12} lg={3}>
          <BooleanInput source="active" />
        </Grid>
        <Grid item xs={12}>
          <ArrayInput source="questions" label="Domande">
            <SimpleFormIterator>
              <ReferenceInput source="id" reference="entities/question">
                <SelectInput
                  label="Testo"
                  optionText={(record) => (record.text.length > 104 ? record.text.substring(0, 104) + '...' : record.text)}
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default QuestionnaireForm;
