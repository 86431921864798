import { Create, Edit } from '@applica-software-guru/react-admin';
import { InterventionCategoryForm, InterventionCategoryList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const InterventionCategoryCreate = () => (
  <Create>
    <InterventionCategoryForm />
  </Create>
);

const InterventionCategoryEdit = () => (
  <Edit>
    <InterventionCategoryForm />
  </Edit>
);

const config = {
  list: InterventionCategoryList,
  edit: InterventionCategoryEdit,
  create: InterventionCategoryCreate,
  options: {
    group: 'control-panel',
    icon: FlagOutlined
  }
};

export default config;
