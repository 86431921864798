import { Create, Edit } from '@applica-software-guru/react-admin';
import { UserForm, UserList } from 'components';

import { CONFIGURED_ROLES } from 'config';
import { UserOutlined } from '@ant-design/icons';

const UserCreate = () => (
  <Create>
    <UserForm configuredRoles={CONFIGURED_ROLES} />
  </Create>
);
const UserEdit = () => (
  <Edit mutationMode="pessimistic">
    <UserForm configuredRoles={CONFIGURED_ROLES} />
  </Edit>
);

const config = {
  list: <UserList configuredRoles={CONFIGURED_ROLES} />,
  edit: UserEdit,
  create: UserCreate,
  options: {
    icon: UserOutlined,
    group: 'control-panel'
  }
};

export default config;
