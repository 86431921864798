import { useParams } from 'react-router';
import { useDataProvider } from '@applica-software-guru/react-admin';
import { useState, useEffect } from 'react';
import { Typography, Card } from '@mui/material';
import SimpleQuestionField from 'components/ra-fields/SimpleQuestionField';
import LabeledQuestionField from 'components/ra-fields/LabeledQuestionField';

const SubmissionViewPage = () => {
  const dataProvider = useDataProvider();
  const urlParams = useParams();
  const campaignId = urlParams.campaignId;
  const [campaign, setCampaign] = useState(null);
  const [userSubmissionData, setUserSubmissionData] = useState(null);

  useEffect(() => {
    async function load() {
      const campaignResponse = await dataProvider.get(`entities/campaign/${campaignId}`);
      const response = await dataProvider.get('submission', { campaignId: campaignId });
      const userSubmissionData = response.data.value;
      setCampaign(campaignResponse.data.value);
      setUserSubmissionData(userSubmissionData);
    }
    load();
  }, [dataProvider, campaignId, userSubmissionData]);

  return campaign !== null && userSubmissionData !== null ? (
    <>
      <Card sx={{ p: 2, m: 1, justifyContent: 'center', display: 'flex' }}>
        <Typography variant="h1">{campaign.description}</Typography>
      </Card>
      {userSubmissionData.questions.map((question) => {
        const relevantEntries = userSubmissionData.entries
          .filter((entry) => entry.questionId === question.id)
          .sort((a, b) => a.answerIndex > b.answerIndex);

        return question.answerLabels.length === 0 ? (
          <SimpleQuestionField key={question.id} question={question} entries={relevantEntries} />
        ) : (
          <LabeledQuestionField key={question.id} question={question} entries={relevantEntries} horizontal={true} />
        );
      })}
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default SubmissionViewPage;
