import {
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useThemeConfig
} from '@applica-software-guru/react-admin';
import { GenderSelectInput } from 'components/ra-inputs';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const UserForm = ({ configuredRoles }) => {
  const { spacing } = useThemeConfig();
  return (
    <SimpleForm defaultValues={{ active: true }}>
      <Grid container spacing={spacing}>
        <Grid item lg={6} xs={12}>
          <TextInput source="email" fullWidth />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput source="password" fullWidth type="password" />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput source="name" fullWidth />
        </Grid>

        <Grid item lg={6} xs={12}>
          <ReferenceInput source="schoolId" reference="entities/school" optionText={(r) => `${r.name}`} fullWidth>
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item lg={6} xs={12}>
          <GenderSelectInput source="sex" fullWidth />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput type="date" source="dateOfBirth" fullWidth />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextInput source="address" fullWidth />
        </Grid>

        {/* TODO: add CityInput */}

        <Grid item lg={6} xs={12}>
          <SelectArrayInput source="roles" label="ra.user.roles" choices={configuredRoles} fullWidth />
        </Grid>
      </Grid>

      <BooleanInput source="active" />
    </SimpleForm>
  );
};

UserForm.propTypes = {
  configuredRoles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired
};

export default UserForm;
