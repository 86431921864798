import { useState } from 'react';
import {
  useThemeConfig,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  Button,
  required,
  useRecordContext,
  useDataProvider
} from '@applica-software-guru/react-admin';
import { Grid } from '@mui/material';
import { CampaignStateField } from 'components/ra-fields';
import { SchoolArrayInputAll } from 'components/ra-inputs';
//import { Navigate } from 'react-router';
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';

const insideValidityTime = (start, end) => {
  // start and end formatted as 'YYYY-MM-DD'
  const now = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);
  return now >= startDate && now <= endDate;
};

const CloseButton = () => {
  const record = useRecordContext();
  const [closing, setClosing] = useState(false);
  const [didClose, setDidClose] = useState(false);
  const navigate = useNavigate();
  console.log(didClose); // fool linter

  const dataProvider = useDataProvider();

  const handleClick = async () => {
    setClosing(true);
    const response = await dataProvider.post('campaign/close', { campaignId: record.id });
    console.log('closeCampaign response: %o', response);
    setDidClose(true);
  };

  if (didClose) {
    navigate(0); // refresh page
  }

  return (
    <>
      {!closing && <Button sx={{ width: '100%', mt: 4 }} onClick={handleClick} variant="contained" label="Chiudi campagna" />}
      {closing && <CircularProgress />}
    </>
  );
};

const CampaignForm = () => {
  const record = useRecordContext();
  // A campaign can be edited if:
  // - Being created
  // - state is either draft or scheduled
  // - is inside validity time
  const canEdit =
    typeof record === 'undefined' ||
    ((record?.campaignState === 'DRAFT' || record?.campaignState === 'SCHEDULED') &&
      insideValidityTime(record?.beginValidity, record?.endValidity));

  const { spacing } = useThemeConfig();
  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <TextInput source="description" validate={required()} />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="note" />
        </Grid>
        <Grid item xs={12}>
          <CampaignStateField source="campaignState" defaultValue="DRAFT" validate={required()} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12}>
          <SchoolArrayInputAll source="schoolIds" validate={required()} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ReferenceInput xs={12} source="questionnaireId" reference="entities/questionnaire" validate={required()} disabled={!canEdit}>
            <SelectInput optionText="title" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput source="beginValidity" type="date" validate={required()} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput source="endValidity" type="date" validate={required()} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <NumberInput source="participation" disabled={true} defaultValue={0} />
        </Grid>
        <Grid item xs={12} lg={4}>
          {record?.campaignState === 'ONGOING' && <CloseButton />}
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default CampaignForm;
