import PropTypes from 'prop-types';
import React from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';

const FreeAnswerField = (props) => {
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const answerValue = props.answerValue;
  const checked = props.checked;

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={checked} name={`${answerKey}-on`} onChange={(e) => e.preventDefault()} />}
        label={answerText}
      />
      <TextField variant="standard" value={answerValue} onChange={(e) => e.preventDefault()} name={answerKey} />
    </div>
  );
};

FreeAnswerField.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired
};

export default FreeAnswerField;
