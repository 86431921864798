import { Stack, Typography } from '@mui/material';

const AnswerLegend = ({ answers }) => (
  <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
    <Typography>Legenda riposte:</Typography>
    {answers?.map((el, i) => {
      return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} key={i}>
          <img alt="legend" src={`leaflet/circle-16-${i}.png`} />
          <Typography>{el.name}</Typography>
        </Stack>
      );
    })}
  </Stack>
);

export default AnswerLegend;
