import { Button, FormControl, LinearProgress } from '@mui/material';

const LoadButton = ({ onClick, disabled, isLoading }) => (
  <FormControl fullWidth>
    {isLoading ? (
      <LinearProgress sx={{ mt: 1.2 }} />
    ) : (
      <Button sx={{ mt: 0.6 }} color="primary" fullWidth={true} onClick={onClick} variant="contained" disabled={disabled}>
        Carica
      </Button>
    )}
  </FormControl>
);

export default LoadButton;
