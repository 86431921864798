import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useThemeConfig,
  NumberInput,
  SelectArrayInput,
  useRecordContext
} from '@applica-software-guru/react-admin';
import { Grid, Alert } from '@mui/material';
import { QuestionTypeSelectInput, AnswerArrayInput } from 'components/ra-inputs';

const QuestionForm = () => {
  const { spacing } = useThemeConfig();
  const record = useRecordContext();
  const labels = record
    ? record.answerLabels.map((label) => {
        return { id: label, name: label };
      })
    : [];

  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={12}>
          <TextInput source="text" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} lg={3}>
          <QuestionTypeSelectInput source="questionType" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} lg={3}>
          <NumberInput label="Num. minimo risposte" source="minAnswers" defaultValue={1} validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} lg={3}>
          <NumberInput label="Num. massimo risposte" source="maxAnswers" defaultValue={1} validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <AnswerArrayInput source="answers" />
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert severity="info" sx={{ mt: 3 }}>
            L'input "Gradi risposta" permette di valutare ogni risposta lungo una scala anzichè una spunta semplice; ad esempio:
            "Scarso","Sufficiente","Buono".
            <br />
            Il punteggio di criticità e interventi associati è distribuito da 0 a 1 nell'ordine in cui le etichette vengono selezionate. Nel
            caso di esempio, i punteggi sono "Scarso" = 0; "Sufficiente" = 0.5; "Buono" = 1.
          </Alert>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SelectArrayInput
            onCreate={() => {
              const newLabel = prompt('Nuova label');
              if (newLabel !== null) {
                labels.push({ id: newLabel, name: newLabel });
                return newLabel;
              }
            }}
            source="answerLabels"
            label="Gradi risposta"
            choices={labels}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BooleanInput source="active" validate={required()} defaultValue={true} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default QuestionForm;
