import { Create, Edit } from '@applica-software-guru/react-admin';
import { I18nMessageForm, I18nMessageList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const MessageCreate = () => (
  <Create>
    <I18nMessageForm />
  </Create>
);

const MessageEdit = () => (
  <Edit>
    <I18nMessageForm />
  </Edit>
);

const config = {
  list: I18nMessageList,
  edit: MessageEdit,
  create: MessageCreate,
  options: {
    group: 'control-panel',
    icon: FlagOutlined
  }
};

export default config;
