import { Create, Edit } from '@applica-software-guru/react-admin';
import { CriticalityCategoryForm, CriticalityCategoryList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const CriticalityCategoryCreate = () => (
  <Create>
    <CriticalityCategoryForm />
  </Create>
);

const CriticalityCategoryEdit = () => (
  <Edit>
    <CriticalityCategoryForm />
  </Edit>
);

const config = {
  list: CriticalityCategoryList,
  edit: CriticalityCategoryEdit,
  create: CriticalityCategoryCreate,
  options: {
    group: 'control-panel',
    icon: FlagOutlined
  }
};

export default config;
