import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useInput } from '@applica-software-guru/react-admin';

const CheckboxAnswerInput = (props) => {
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const onChange = props.onChange;
  const [value, setValue] = useState('checkbox-true');

  const flipValue = (event) => {
    onChange(event);
    const nextValue = event.target.checked ? 'checkbox-false' : 'checkbox-true';
    setValue(nextValue);
  };

  const { field } = useInput({ source: answerKey, onChange: flipValue });

  return <FormControlLabel control={<Checkbox {...field} name={answerKey} value={value} />} label={answerText} />;
};

CheckboxAnswerInput.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default CheckboxAnswerInput;
