import 'leaflet/dist/leaflet.css';
import { MAP_MAX_MARKERS } from '../../config';
import { Stack, Card } from '@mui/material';
import { useThemeConfig, Form, useDataProvider } from '@applica-software-guru/react-admin';
import MapFilterBar from './MapFilterBar';
import { useState, useRef } from 'react';

import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet/hooks';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

var SchoolIcon = L.Icon.extend({
  options: {
    iconUrl: 'leaflet/school.png',
    iconSize: [36, 36],
    iconAnchor: [18, 18]
  }
});

const CircleIcons = [];
for (var i = 0; i <= 10; i++) {
  CircleIcons.push(
    L.Icon.extend({
      options: {
        iconUrl: `leaflet/circle-16-${i}.png`,
        iconSize: [10, 10],
        iconAnchor: [5, 5]
      }
    })
  );
}

const isCheckboxEntryVisible = (bounds, point, activeAnswers) => {
  return (
    activeAnswers.indexOf(point.aid) > -1 &&
    Number.isFinite(point.pos[0]) && // NaN check
    Number.isFinite(point.pos[1]) && // necessary for bounds.contains()
    bounds.contains(point.pos)
  );
};

const isRadioEntryVisible = (bounds, point, activeAnswer, activeAnswerLabels) => {
  return (
    activeAnswerLabels.indexOf(point.val) > -1 &&
    activeAnswer === point.aid &&
    Number.isFinite(point.pos[0]) && // NaN check
    Number.isFinite(point.pos[1]) && // necessary for bounds.contains()
    bounds.contains(point.pos)
  );
};

const MapReportPage = () => {
  const { spacing } = useThemeConfig();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [isRadioQuestion, setIsRadioQuestion] = useState(null);
  const [activeCheckBoxAnswers, setActiveCheckBoxAnswers] = useState([]);
  const [activeRadioAnswer, setActiveRadioAnswer] = useState(0);
  const [activeAnswerLabels, setActiveAnswerLabels] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [schools, setSchools] = useState([]);
  const mapRef = useRef(null);

  const loadMapData = (filters) => {
    const load = async ({ campaignId, schoolIds, questionId }) => {
      const campaignResponse = await dataProvider.getOne('entities/campaign', { id: campaignId });
      let schoolData = null;
      if (schoolIds.includes('all')) {
        const schoolsResponse = await dataProvider.getMany('entities/school', { ids: campaignResponse.data.schoolIds });
        schoolData = schoolsResponse.data;
      } else {
        const schoolsResponse = await dataProvider.getMany('entities/school', { ids: schoolIds });
        schoolData = schoolsResponse.data;
      }

      const mapDataResponse = await dataProvider.get('report/map', { campaignId, schoolIds, questionId });
      const mapDataRows = mapDataResponse.data.value;

      // convert position to numeric array for use by leaflet
      mapDataRows.forEach((row) => {
        row.pos = [parseFloat(row.lat), parseFloat(row.lon)];
      });

      const questionParams = {
        filters: [{ property: '_id', type: 'eq', value: questionId }],
        page: 1,
        rowsPerPage: 1000,
        sorts: [{ property: 'id', descending: false }]
      };
      const questionResponse = await dataProvider.post(`entities/question/find`, questionParams);
      const questionRow = questionResponse.data.value.rows[0]; // TODO: introduce null check

      setReportData(mapDataRows);
      setIsLoading(false);
      setSchools(schoolData);
      setQuestion(questionRow);
      const isRadioQuestion = questionRow.answerLabels.length >= 1;
      setIsRadioQuestion(isRadioQuestion);
      if (isRadioQuestion) {
        setActiveRadioAnswer(0);
        setActiveAnswerLabels(questionRow.answerLabels.map((el, i) => i));
      } else {
        setActiveCheckBoxAnswers(questionRow.answers.map((el, i) => i));
        setActiveAnswerLabels([]);
      }
    };

    // performance profiling
    const startLoad = performance.now();

    setIsLoading(true);
    load(filters);

    // print profiling info
    const finishLoad = performance.now();
    console.log('performance - MapReportPage - Load time: %o ms ', finishLoad - startLoad);
  };

  const stuff = (
    <div>
      <Stack direction="column" spacing={spacing}>
        <Card>
          <Form>
            <MapFilterBar
              onAnswerChange={(value) => setActiveCheckBoxAnswers(value)}
              onRadioAnswerChange={(value) => setActiveRadioAnswer(value)}
              onAnswerLabelChange={(value) => setActiveAnswerLabels(value)}
              onLoadButtonClick={(value) => loadMapData(value)}
              isLoading={isLoading}
              question={question}
            />
          </Form>
        </Card>
        <Card>
          <MapContainer
            key={mapRef.current?.getBounds().toString()}
            ref={mapRef}
            center={[40.666, 16.6]}
            zoom={15}
            scrollWheelZoom={false}
            className="map-container"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapZoomEventHandler
              reportData={reportData}
              schools={schools}
              activeCheckBoxAnswers={activeCheckBoxAnswers}
              activeRadioAnswer={activeRadioAnswer}
              activeAnswerLabels={activeAnswerLabels}
              isRadioQuestion={isRadioQuestion}
            />
          </MapContainer>
        </Card>
      </Stack>
    </div>
  );

  return stuff;
};

const MapZoomEventHandler = ({ reportData, schools, activeCheckBoxAnswers, activeRadioAnswer, activeAnswerLabels, isRadioQuestion }) => {
  const map = useMapEvents({
    load: () => setMapBounds(map.getBounds()),
    zoom: () => setMapBounds(map.getBounds()),
    moveend: () => setMapBounds(map.getBounds())
  });

  const [mapBounds, setMapBounds] = useState(map.getBounds());

  const stuff = (
    <MarkerLayer
      reportData={reportData}
      schools={schools}
      bounds={mapBounds}
      activeCheckBoxAnswers={activeCheckBoxAnswers}
      activeRadioAnswer={activeRadioAnswer}
      activeAnswerLabels={activeAnswerLabels}
      isRadioQuestion={isRadioQuestion}
    />
  );

  return stuff;
};

const MarkerLayer = ({ reportData, schools, bounds, activeCheckBoxAnswers, activeRadioAnswer, activeAnswerLabels, isRadioQuestion }) => {
  // performance profiling
  const startRender = performance.now();
  let visible = 0;
  let rendered = 0;

  const stuff = [];
  if (reportData !== null && bounds !== null) {
    for (const point of reportData) {
      if (
        (isRadioQuestion && isRadioEntryVisible(bounds, point, activeRadioAnswer, activeAnswerLabels)) ||
        (!isRadioQuestion && isCheckboxEntryVisible(bounds, point, activeCheckBoxAnswers))
      ) {
        if (visible < MAP_MAX_MARKERS) {
          stuff.push(<Marker key={rendered} position={point.pos} icon={new CircleIcons[isRadioQuestion ? point.val : point.aid]()} />);
          rendered++;
        }
        visible++;
      }
    }

    for (const school of schools) {
      if (
        Number.isFinite(school.latitude) && // NaN check
        Number.isFinite(school.longitude) && // necessary for bounds.contains()
        bounds.contains([school.latitude, school.longitude])
      ) {
        stuff.push(
          <Marker key={rendered} position={[school.latitude, school.longitude]} icon={new SchoolIcon()}>
            <Popup>
              {school.name} <br /> {school.address}
            </Popup>
          </Marker>
        );
        rendered++;
        visible++;
      }
    }
  }

  // print profiling info
  const finishRender = performance.now();
  console.log(
    'performance - MarkerLayer - %o answers scanned, %o within map boundary, %o rendered. Compute time: %o ms ',
    reportData?.length || 0,
    visible,
    rendered,
    finishRender - startRender
  );

  return stuff;
};

export default MapReportPage;
