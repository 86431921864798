import { ReadonlyField, SimpleForm, TextInput, required, useRecordContext, useThemeConfig } from '@applica-software-guru/react-admin';

import { Grid } from '@mui/material';

const DeviceForm = () => {
  const { spacing } = useThemeConfig();
  const record = useRecordContext();
  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        {record?.id && (
          <Grid item xs={12} sm={12}>
            <ReadonlyField source="registrationDate" fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextInput source="code" validate={required()} fullWidth />
        </Grid>
        {record?.id && (
          <Grid item xs={12} sm={12}>
            <TextInput source="secret" validate={required()} fullWidth />
          </Grid>
        )}
      </Grid>
    </SimpleForm>
  );
};

export default DeviceForm;
