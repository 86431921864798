import PropTypes from 'prop-types';
import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const CheckboxAnswerField = (props) => {
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const checked = props.checked;

  return (
    <FormControlLabel control={<Checkbox name={answerKey} checked={checked} onChange={(e) => e.preventDefault()} />} label={answerText} />
  );
};

CheckboxAnswerField.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired
};

export default CheckboxAnswerField;
