import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useInput } from '@applica-software-guru/react-admin';
import { useFormContext } from 'react-hook-form';

const FreeAnswerInput = (props) => {
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const [checked, setChecked] = useState(false);
  const onChange = props.onChange;

  const { field } = useInput({ source: answerKey });
  const { resetField } = useFormContext();

  // TODO: verify the perfomance penalty of resetField, it looks slower
  const onCheckBoxChange = (event) => {
    setChecked(!checked);
    resetField(answerKey);
    onChange(event);
  };

  return (
    <div>
      <FormControlLabel control={<Checkbox checked={checked} name={`${answerKey}-on`} onChange={onCheckBoxChange} />} label={answerText} />
      <TextField {...field} variant="standard" disabled={!checked} name={answerKey} />
    </div>
  );
};

FreeAnswerInput.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default FreeAnswerInput;
