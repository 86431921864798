let environment = 'PRODUCTION';
let appUrl = `//${document.location.host}/`;
if (appUrl.endsWith(':3000/')) {
  appUrl = 'http://localhost:8080/';
  environment = 'DEVELOPER';
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const ENVIRONMENT = environment;
export const APP_NAME = 'Mobility';
export const FILE_FIELDS = {
  images: ['image'],
  files: ['file'],
  attachments: ['attachment']
};
export const CONFIGURED_ROLES = [
  { id: 'ROLE_ADMIN', name: 'Admin' },
  { id: 'ROLE_AREA_MANAGER', name: 'Area Manager' },
  { id: 'ROLE_MANAGER', name: 'School Manager' },
  { id: 'ROLE_USER', name: 'User' }
];
export const MAP_MAX_MARKERS = 750;
