import { SelectArrayInput } from '@applica-software-guru/react-admin';

const SubLevelAnswerInput = ({ name, onChange, answerLabels, defaultValue }) => (
  <SelectArrayInput
    name={name}
    onChange={onChange}
    source="activeAnswerLabels"
    label="Gradi risposta"
    choices={answerLabels}
    defaultValue={defaultValue}
    disabled={answerLabels === undefined || answerLabels === null || answerLabels.length < 1}
  />
);

export default SubLevelAnswerInput;
