import React, { useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Paper, Grid, Button, FormControl, FormHelperText, LinearProgress, Alert } from '@mui/material';
import { SchoolArrayInputAllV2, CampaignSelectInput } from 'components/ra-inputs';

const canSubmit = (f) => typeof f.filters?.campaignId !== 'undefined' && f.filters?.campaignId !== null;

const ChartFilterBar = ({ onButtonClick, onExportClick, isLoading, showExport }) => {
  const filters = useWatch();

  // propagate the updated filters values to parent
  const handleReload = useCallback(() => {
    if (canSubmit(filters)) {
      onButtonClick(filters);
    }
  }, [filters, onButtonClick]);

  const handleExport = useCallback(() => {
    if (canSubmit(filters)) {
      onExportClick(filters);
    }
  }, [filters, onExportClick]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  return (
    <Paper elevation={24} sx={{ p: 4, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={2}>
          <CampaignSelectInput label="Campagna" validation="required" source="filters.campaignId" />
        </Grid>
        <Grid item xs={6} lg={2}>
          <SchoolArrayInputAllV2 label="Istituto" validation="required" source="filters.schoolIds" />
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth sx={{ mt: 0.5 }}>
            <FormHelperText>&nbsp;</FormHelperText>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Button color="primary" fullWidth={true} onClick={handleReload} variant="contained" disabled={!canSubmit(filters)}>
                Carica
              </Button>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          {showExport && (
            <Button color="primary" sx={{ mt: 3.4 }} fullWidth={true} onClick={handleExport} variant="contained">
              Esporta Excel
            </Button>
          )}
        </Grid>
        <Grid item xs={6} lg={6}>
          {!canSubmit(filters) && (
            <Alert severity="info" sx={{ mt: 3 }}>
              Seleziona una Campagna per iniziare.
            </Alert>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

ChartFilterBar.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default ChartFilterBar;
