// prima chiamata popola il form
// al posto del loader viene renderizzato il CustomUserForm
// il tasto Salva fa chiamata ad endpoint custom che ne lcaso di utenteze non privilegiate, impedisce di salvare utenti diversi dal proprio
import React, { useState, useCallback, useEffect } from 'react';
import { Form, useDataProvider } from '@applica-software-guru/react-admin';
import { Card, Grid, CircularProgress, Alert } from '@mui/material';
import UserProfileFilterBar from './UserProfileFilterBar';

import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const UserProfilePage = () => {
  const [userCoordinates, setUserCoordinates] = useState([40.666, 16.6]);
  const [address, setAddress] = useState('Inserire indirizzo...');
  const [userProfile, setUserProfile] = useState({});
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const dataProvider = useDataProvider();

  const handleAddressChange = useCallback(
    (values) => {
      setUserCoordinates(values.userCoordinates);
      setAddress(values.address);
    },
    [setUserCoordinates, setAddress]
  );

  const handleSaveClick = useCallback(
    (values, userCoordinates, userAddress) => {
      setSaving(true);
      setSaved(false);
      dataProvider
        .post('profile/save', {
          name: values.name,
          sex: values.sex,
          dateOfBirth: values.dateOfBirth,
          schoolId: values.schoolId,
          coordinates: userCoordinates,
          address: userAddress
        })
        .then(() => {
          setSaving(false);
          setSaved(true);
        });
    },
    [dataProvider]
  );

  useEffect(() => {
    const load = async () => {
      const response = await dataProvider.get('profile/get', {});
      let userProfile = response.data.value;
      setUserProfile(userProfile);
      if (userProfile?.latitude && userProfile?.longitude) {
        setUserCoordinates([userProfile.latitude, userProfile.longitude]);
      }
      if (userProfile?.address) {
        setAddress(userProfile.address);
      }
    };

    load();
  }, [dataProvider, setUserProfile, setUserCoordinates, setAddress]);

  return (
    <>
      <Grid container>
        <Grid item lg={4}>
          <Card sx={{ p: 2 }}>
            <Form sx={{ m: 2 }}>
              <UserProfileFilterBar onChange={handleAddressChange} onClick={handleSaveClick} userProfile={userProfile} />
              {saved && <Alert severity="success">Profilo aggiornato.</Alert>}
              {saving && <CircularProgress />}
            </Form>
          </Card>
        </Grid>
        <Grid item lg={8}>
          <Card sx={{ p: 2, height: '100%' }}>
            <MapContainer
              key={userCoordinates}
              sx={{ m: 2, height: '100%' }}
              center={userCoordinates}
              zoom={15}
              scrollWheelZoom={false}
              className="map-container"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <Marker position={userCoordinates}>
                <Popup>{address}</Popup>
              </Marker>
            </MapContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfilePage;
