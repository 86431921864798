import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
  useThemeConfig,
  useTranslate
} from '@applica-software-guru/react-admin';

import { Alert } from '@mui/material';

const AlertBox = () => {
  const { spacing } = useThemeConfig();
  const translate = useTranslate();
  return (
    <Alert sx={{ p: spacing, m: spacing }} severity="info">
      {translate('resources.entities/school.alert')}
    </Alert>
  );
};
const SchoolList = () => (
  <List perPage={25} filters={[<SearchInput source="keyword" alwaysOn />]}>
    <AlertBox />
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <ReferenceField source="schoolTypeId" reference="entities/schoolType">
        <FunctionField render={(r) => `${r.name}`} />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default SchoolList;
