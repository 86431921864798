import { useState, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { LabeledInput, useDataProvider, useInput } from '@applica-software-guru/react-admin';

/*
 * TODO: CRITICAL BUG
 *
 * FIX INFINITE RENDER LOOP
 *
 */

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

const SchoolArrayInputAll = (props) => {
  const { field } = useInput({ ...props, defaultValue: [] });
  const [schools, setSchools] = useState([]);
  const [schoolIds, setSchoolIds] = useState(field.value);

  const theme = useTheme();
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getList('entities/school', {});
      setSchools(response.data);
    }
    fetchData();
  }, [dataProvider, setSchools]);

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      if (value[value.length - 1] === 'all') {
        const newValue = schoolIds.length === schools.length ? [] : schools.map((school) => school.id);
        setSchoolIds(newValue);
        field.onChange(newValue);
        return;
      }
      setSchoolIds(value);
      field.onChange(value);
    },
    [schoolIds, schools, setSchoolIds, field]
  );

  // TODO: FIX INFINITE RENDER LOOP - use console.log to detect
  console.log('debug - SchoolArrayInputAll - render');

  return (
    <LabeledInput name="school-array" label="Scuole">
      <Select multiple value={field.value} input={<OutlinedInput label="School" />} MenuProps={MenuProps} onChange={handleChange}>
        <MenuItem key="all" value="all">
          Seleziona tutte...
        </MenuItem>
        {schools.map((school) => (
          <MenuItem key={school.id} value={school.id} style={getStyles(school, schoolIds, theme)}>
            {school.name}
          </MenuItem>
        ))}
      </Select>
    </LabeledInput>
  );
};

export default SchoolArrayInputAll;
