import { SimpleForm, SmartTextInput, TextInput, useThemeConfig } from '@applica-software-guru/react-admin';

import { Grid } from '@mui/material';
import { LangSelectInput } from '../ra-inputs';

const I18nMessageForm = () => {
  const { spacing } = useThemeConfig();
  return (
    <SimpleForm defaultValues={{ lang: 'it' }}>
      <Grid container spacing={spacing}>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <LangSelectInput source="lang" />
        </Grid>
        <Grid item lg={9} md={9} sm={8} xs={12}>
          <TextInput source="code" fullWidth helperText="Code mapped/or to map for the message" />
        </Grid>
        <Grid item xs={12}>
          <SmartTextInput maxLength={200} source="text" fullWidth multiline rows={3} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
export default I18nMessageForm;
