import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { email, required, useAuthProvider, useNotify, useRedirect, useTranslate } from 'ra-core';

import { Form } from 'react-admin';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput, TextInput } from '@applica-software-guru/react-admin';
import { useState } from 'react';
import AuthWrapper from './AuthWrapper';
import { MapInput } from 'components';
import { GenderSelectInput } from 'components/ra-inputs';

const CustomRegisterPage = ({ name, copy, version }) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const authProvider = useAuthProvider();

  const handleSubmit = (values) => {
    setLoading(true);
    const formValues = {
      name: values.name,
      email: values.email,
      dateOfBirth: values.dateOfBirth,
      sex: values.sex,
      address: values.map.address,
      schoolId: values.schoolId,
      lat: values.map.lat,
      lng: values.map.lng
    };
    authProvider
      .register(formValues)
      .then((response) => {
        const message = `ra.auth.register_${response.responseCode}`;
        const type = response.responseCode === 'ok' ? 'info' : 'error';
        // @ts-ignore
        notify(message, { type });
        redirect('/login');
      })
      .catch((error) => {
        notify(error?.message || error?.toString(), { type: 'error' });
      })
      .finally(() => setLoading(false));
  };
  return (
    <AuthWrapper name={name} copy={copy} version={version}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">{translate('ra.auth.register.title')}</Typography>
            <Typography component={Link} to={'/login'} variant="body1" sx={{ textDecoration: 'none' }} color="primary">
              {translate('ra.auth.already_have_account')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <TextInput source="name" label={translate('ra.register.name')} validate={required()} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <TextInput source="email" type="email" validate={[required(), email()]} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <TextInput type="date" source="dateOfBirth" label={translate('ra.register.date_of_birth')} validate={required()} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <GenderSelectInput source="sex" label={translate('ra.register.gender')} validate={required()} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <ReferenceInput
                  source="schoolId"
                  reference="values/school"
                  label={translate('ra.register.school')}
                  optionText={(r) => `${r.name}`}
                  validate={required()}
                >
                  <AutocompleteInput
                    filterToQuery={(searchText) => {
                      return {
                        name__like: searchText
                      };
                    }}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <MapInput source="map" validate={required()} label={translate('ra.register.address')} />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                {loading ? <CircularProgress size={19} thickness={3} /> : translate('ra.auth.create_account')}
              </Button>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

CustomRegisterPage.propTypes = {
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
};

export default CustomRegisterPage;
