import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material';

const RadioAnswerField = (props) => {
  const answerLabels = props.answerLabels || [];
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const answerValue = props.answerValue;
  const horizontal = props.horizontal;

  return (
    <FormControl sx={{ my: 1 }}>
      <FormLabel>{answerText}</FormLabel>
      <RadioGroup name={answerKey} defaultValue={answerValue} row={horizontal}>
        {answerLabels.map((label, index) => {
          return (
            <FormControlLabel
              key={`${answerKey}-${index}`}
              control={<Radio value={index} onChange={(e) => e.preventDefault()} />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

RadioAnswerField.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  answerLabels: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RadioAnswerField;
