import { SimpleForm, TextInput, BooleanInput, required, useThemeConfig } from '@applica-software-guru/react-admin';

import { Grid } from '@mui/material';

const CriticalityCategoryForm = () => {
  const { spacing } = useThemeConfig();
  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={6}>
          <TextInput source="description" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} lg={6}>
          <BooleanInput source="active" validate={required()} defaultValue={true} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default CriticalityCategoryForm;
