import { SelectArrayInput, ReferenceArrayInput } from '@applica-software-guru/react-admin';

const InterventionSelectArrayInput = (props) => {
  return (
    <ReferenceArrayInput {...props} reference="entities/intervention" optionText="description" fullWidth>
      <SelectArrayInput fullWidth />
    </ReferenceArrayInput>
  );
};

export default InterventionSelectArrayInput;
