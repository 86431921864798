import React, { useCallback, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Paper, Grid, Alert } from '@mui/material';
import { SchoolArrayInputAllV2, CampaignSelectInput } from 'components/ra-inputs';
import QuestionInput from './MapReport/QuestionInput';
import LoadButton from './MapReport/LoadButton';
import AnswerLegend from './MapReport/AnswerLegend';
import CheckboxAnswerInput from './MapReport/CheckboxAnswerInput';
import TopLevelAnswerInput from './MapReport/TopLevelAnswerInput';
import SubLevelAnswerInput from './MapReport/SubLevelAnswerInput';

const canSubmit = (campaignId, questionId) => {
  return typeof campaignId !== 'undefined' && campaignId !== null && typeof questionId !== 'undefined' && questionId !== null;
};

const MapFilterBar = ({ onLoadButtonClick, onAnswerChange, onRadioAnswerChange, onAnswerLabelChange, isLoading, question }) => {
  const { resetField } = useFormContext();
  const campaignId = useWatch({ name: 'campaignId' });
  const schoolIds = useWatch({ name: 'schoolIds' });
  const questionId = useWatch({ name: 'questionId' });
  const activeCheckBoxAnswers = useWatch({ name: 'activeCheckBoxAnswers' });

  // propagate the updated filters values to parent
  const handleAnswerChange = useCallback((e) => onAnswerChange(e.target.value), [onAnswerChange]);
  const handleRadioAnswerChange = useCallback((e) => onRadioAnswerChange(e.target.value), [onRadioAnswerChange]);
  const handleAnswerLabelChange = useCallback((e) => onAnswerLabelChange(e.target.value), [onAnswerLabelChange]);
  const handleReload = useCallback(() => {
    if (canSubmit(campaignId, questionId)) {
      // experimental. reset on reload click need
      resetField('activeAnswer');
      resetField('activeAnswers');
      resetField('activeAnswerLabels');
      onLoadButtonClick({ campaignId, schoolIds, questionId, activeCheckBoxAnswers });
    }
  }, [onLoadButtonClick, campaignId, schoolIds, questionId, activeCheckBoxAnswers, resetField]);

  const isRadioQuestion = question?.answerLabels?.length >= 1;
  const answers = question?.answers.map((el, i) => {
    return { id: i, name: el.text };
  });
  const defaultAnswers = answers?.map((el) => el.id);
  const answerLabels = question?.answerLabels.map((el, i) => {
    return { id: i, name: el };
  });
  const defaultAnswerLabels = answerLabels?.map((el) => el.id);

  useEffect(() => resetField('questionId'), [campaignId, resetField]);

  return (
    <Paper elevation={24} sx={{ p: 4, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <CampaignSelectInput name="campaignId" label="Campagna" validation="required" source="campaignId" />
        </Grid>
        <Grid item xs={12} lg={4}>
          <QuestionInput name="questionId" label="Domanda" validation="required" source="questionId" campaignId={campaignId} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SchoolArrayInputAllV2 name="schoolIds" label="Istituto" validation="required" source="schoolIds" />
        </Grid>
        <Grid item xs={12} lg={8}>
          {!canSubmit(campaignId, questionId) && (
            <Alert severity="info" sx={{ mt: 0.5 }}>
              Seleziona una Campagna e una Domanda per iniziare.
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <LoadButton onClick={handleReload} disabled={!canSubmit(campaignId, questionId)} isLoading={isLoading} />
        </Grid>
        {isRadioQuestion ? (
          <>
            <Grid item xs={6}>
              <TopLevelAnswerInput name="activeAnswer" onChange={handleRadioAnswerChange} answers={answers} defaultValue={0} />
            </Grid>
            <Grid item xs={6}>
              <SubLevelAnswerInput
                name="activeAnswerLabels"
                onChange={handleAnswerLabelChange}
                answerLabels={answerLabels}
                defaultValue={defaultAnswerLabels}
              />
            </Grid>
          </>
        ) : answers?.length > 0 ? (
          <Grid item xs={12}>
            <CheckboxAnswerInput name="activeAnswers" onChange={handleAnswerChange} answers={answers} defaultValue={defaultAnswers} />
          </Grid>
        ) : (
          <div></div>
        )}
        {answers?.length > 0 ? (
          <Grid item xs={12}>
            <AnswerLegend answers={isRadioQuestion ? answerLabels : answers} />
          </Grid>
        ) : (
          <div></div>
        )}
      </Grid>
    </Paper>
  );
};

MapFilterBar.propTypes = {
  onLoadButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default MapFilterBar;
