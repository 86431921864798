import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';

import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import React, { useCallback, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import MapSearchInput from './MapSearchInput';

const MapInput = ({ source, fullWidth, center = [40.666, 16.6], zoom = 16, disabled, ...props }) => {
  const map = useRef(null);
  const { setValue } = useFormContext();
  const [state, setState] = useState({ mapLat: center[0], mapLng: center[1], mapLabel: '' });

  // set position on map when user selects address
  const handleChange = useCallback(
    (position) => {
      map.current?.flyTo([position.geometry.location.lat, position.geometry.location.lng], zoom, {
        animate: true,
        duration: 1.5
      });
      setValue(source, { lat: position.geometry.location.lat, lng: position.geometry.location.lng, address: position.formatted_address });
      setState({
        ...state,
        mapLat: position.geometry.location.lat,
        mapLng: position.geometry.location.lng,
        mapLabel: position.formatted_address
      });
    },
    [map, setValue, state, setState, source, zoom]
  );
  return (
    <>
      <MapSearchInput {...props} fullWidth disabled={disabled} onChange={handleChange} />
      <MapContainer className="map-container" center={[state.mapLat, state.mapLng]} zoom={zoom} scrollWheelZoom={false} ref={map}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[state.mapLat, state.mapLng]}>
          <Popup>{state.mapLabel}</Popup>
        </Marker>
      </MapContainer>
    </>
  );
};

export default MapInput;
