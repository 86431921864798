import {
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useThemeConfig
} from '@applica-software-guru/react-admin';

import { Grid, Alert } from '@mui/material';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

var SchoolIcon = L.Icon.extend({
  options: {
    iconUrl: 'leaflet/school.png',
    iconSize: [36, 36],
    iconAnchor: [18, 18]
  }
});

const SchoolForm = () => {
  const { spacing } = useThemeConfig();
  const record = useRecordContext();

  return (
    <SimpleForm>
      <Grid container spacing={spacing}>
        <Grid item xs={12} md={4}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="description" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            source="schoolTypeId"
            reference="entities/schoolType"
            optionText={(r) => `${r.name}`}
            validate={required()}
            fullWidth
          >
            <SelectInput />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberInput source="numStudents" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <NumberInput source="numWorkers" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="address" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert severity="info">
            {record?.latitude && record?.longitude
              ? 'La posizione sulla mappa verrà aggiornata dopo il salvataggio.'
              : "La posizione dell'istituto verrà visualizzata in una mappa dopo il salvataggio(l'indirizzo deve essere valido)."}
          </Alert>
        </Grid>
        <Grid item xs={12} md={12}>
          {record?.latitude && record?.longitude && (
            <MapContainer className="map-container" center={[record.latitude, record.longitude]} zoom={16} scrollWheelZoom={false}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[record.latitude, record.longitude]} icon={new SchoolIcon()}></Marker>
            </MapContainer>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <BooleanInput source="active" validate={required()} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default SchoolForm;
