import { SelectInput } from '@applica-software-guru/react-admin';
const GenderSelectInput = (props) => (
  <SelectInput
    {...props}
    choices={[
      { id: 'M', name: 'M' },
      { id: 'F', name: 'F' },
      { id: 'A', name: 'Altro' }
    ]}
  />
);

export default GenderSelectInput;
