import { Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';

function Footer(props) {
  const { name, version } = props;
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Typography variant="caption">
        <span dangerouslySetInnerHTML={{ __html: props?.copy || '' }} /> {name} - {version}
      </Typography>
    </Stack>
  );
}

Footer.propTypes = {
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  copy: PropTypes.string
};
Footer.defaultProps = {
  copy: '&copy; Applica Software Guru for'
};

export { Footer };
