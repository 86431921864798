import { CoverField, useRecordContext } from '@applica-software-guru/react-admin';

import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';

const UserPictureField = ({ source, ...props }) => {
  const record = useRecordContext();
  const image = get(record, source);
  if (!image) {
    return null;
  }
  return <CoverField {...props} circle width={50} height={50} justify="flex-end" />;
};

UserPictureField.propTypes = {
  source: PropTypes.string.isRequired
};

export default UserPictureField;
