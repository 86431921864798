import { Create, Edit } from '@applica-software-guru/react-admin';
import { CampaignForm, CampaignList } from 'components';

import { FormOutlined } from '@ant-design/icons';

const CampaignCreate = () => (
  <Create>
    <CampaignForm />
  </Create>
);

const CampaignEdit = () => (
  <Edit>
    <CampaignForm />
  </Edit>
);

const config = {
  list: CampaignList,
  edit: CampaignEdit,
  create: CampaignCreate,
  options: {
    group: 'campaign-pages',
    icon: FormOutlined
  }
};

export default config;
