import { SelectInput, ReferenceInput } from '@applica-software-guru/react-admin';

const SchoolSelectInput = (props) => {
  return (
    <ReferenceInput {...props} reference="entities/school" optionText="name" fullWidth>
      <SelectInput fullWidth />
    </ReferenceInput>
  );
};

export default SchoolSelectInput;
