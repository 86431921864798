import PropTypes from 'prop-types';
import React from 'react';
import RadioAnswerField from './RadioAnswerField';

import { FormGroup, Typography, Card } from '@mui/material';
import { useThemeConfig } from '@applica-software-guru/react-admin';

const LabeledQuestionField = (props) => {
  const { spacing } = useThemeConfig();

  const question = props.question;
  const entries = props.entries;
  const answers = question.answers;
  const labels = question.answerLabels;
  const horizontal = props.horizontal;

  return (
    <Card spacing={spacing} sx={{ p: 2, m: 1 }}>
      <Typography variant="h3" sx={{ my: 1 }}>
        {question.text}
      </Typography>
      <FormGroup>
        {answers.map((answer, index) => {
          // find relevant entry and pass values if present
          const relevantEntry = entries.find((el) => el.answerIndex === index);
          const checked = relevantEntry !== undefined; // this doesn't apply
          const answerValue = relevantEntry !== undefined ? relevantEntry.value : undefined;

          return (
            <RadioAnswerField
              key={`${question.id}-${index}`}
              answerKey={`${question.id}-${index}`}
              answerText={answer.text}
              answerValue={answerValue}
              checked={checked}
              answerLabels={labels}
              horizontal={horizontal}
            />
          );
        })}
      </FormGroup>
    </Card>
  );
};

LabeledQuestionField.propTypes = {
  question: PropTypes.object.isRequired
};

export default LabeledQuestionField;
