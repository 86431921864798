import { Create, Edit } from '@applica-software-guru/react-admin';
import { DeviceForm, DeviceList } from 'components';
const DeviceCreate = () => (
  <Create>
    <DeviceForm />
  </Create>
);
const DeviceEdit = () => (
  <Edit>
    <DeviceForm />
  </Edit>
);

const config = {
  list: DeviceList,
  edit: DeviceEdit,
  create: DeviceCreate,
  options: {
    group: 'control-panel'
  }
};

export default config;
