//export default (csv: string, filename: string): void => {
//https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/export/downloadCSV.ts

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const downloadFile = (excel, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  //const blob = new Blob([excel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const blob = b64toBlob(excel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, `${filename}`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}`);
    fakeLink.click();
  }
};

export default downloadFile;
