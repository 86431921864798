import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  TextInput,
  useThemeConfig
} from '@applica-software-guru/react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

const AnswerArrayInput = (props) => {
  //TODO: useTranslate() for labels
  const { spacing } = useThemeConfig();

  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ getSource, formData }) => (
            <Accordion expanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TextInput source={getSource('text')} label={'Testo risposta:'} defaultValue={'Nuova risposta...'} />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={spacing}>
                  {formData.questionType === 'CURRENT_STATE' && (
                    <Grid item xs={4}>
                      <ReferenceArrayInput
                        reference="entities/criticality"
                        source={getSource('associatedCriticalityIds')}
                        label={'Criticità associate:'}
                      >
                        <SelectArrayInput optionText="description" />
                      </ReferenceArrayInput>
                    </Grid>
                  )}
                  {formData.questionType === 'PROPENSION' && (
                    <Grid item xs={4}>
                      <ReferenceArrayInput
                        reference="entities/intervention"
                        source={getSource('associatedInterventionIds')}
                        label={'Interventi associati:'}
                      >
                        <SelectArrayInput optionText="description" />
                      </ReferenceArrayInput>
                    </Grid>
                  )}
                  {typeof formData.answerLabels !== 'undefined' && formData.answerLabels.length === 0 && (
                    <Grid item xs={4}>
                      <BooleanInput source={getSource('freeAnswer')} defaultValue={false} label={'Campo a risposta libera'} />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default AnswerArrayInput;
