import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { useInput } from '@applica-software-guru/react-admin';

const RadioAnswerInput = (props) => {
  const answerLabels = props.answerLabels || [];
  const answerKey = props.answerKey;
  const answerText = props.answerText;
  const row = props.horizontal || false;
  const onFirstChange = props.onFirstChange;
  const { field } = useInput({
    source: answerKey,
    onChange: () => {
      if (!hasChanged) {
        onFirstChange();
      }
      setHasChanged(true);
    }
  });
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <FormControl sx={{ my: 1 }}>
      <FormLabel>{answerText}</FormLabel>
      <RadioGroup {...field} row={row} defaultValue={0} name={answerKey}>
        {answerLabels.map((label, index) => {
          return <FormControlLabel key={`${answerKey}-${index}`} control={<Radio value={index} />} label={label} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

RadioAnswerInput.propTypes = {
  answerKey: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  answerLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func
};

export default RadioAnswerInput;
