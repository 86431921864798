import { SelectInput, ReferenceInput } from '@applica-software-guru/react-admin';

const CampaignSelectInput = (props) => {
  return (
    <ReferenceInput {...props} reference="entities/campaign" optionText="description" fullWidth>
      <SelectInput fullWidth />
    </ReferenceInput>
  );
};

export default CampaignSelectInput;
