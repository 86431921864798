import { Create, Edit } from '@applica-software-guru/react-admin';
import { CriticalityForm, CriticalityList } from 'components';

import { FlagOutlined } from '@ant-design/icons';

const CriticalityCreate = () => (
  <Create>
    <CriticalityForm />
  </Create>
);

const CriticalityEdit = () => (
  <Edit>
    <CriticalityForm />
  </Edit>
);

const config = {
  list: CriticalityList,
  edit: CriticalityEdit,
  create: CriticalityCreate,
  options: {
    group: 'anagraphics',
    icon: FlagOutlined
  }
};

export default config;
