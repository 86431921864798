import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
const BasicBarChart = ({ series, categories, type = 'bar', height = 200 }) => {
  const chartOptions = {
    chart: {
      height: 'auto',
      type,
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'straight'
    },

    dataLabels: {
      enabled: false
    },

    plotOptions: {
      [type]: {
        columnWidth: '90%'
      }
    },
    xaxis: {
      //labels: {
      //  show: false
      //}
      categories: categories
    },
    yaxis: {
      decimalsInFloat: 1,
      labels: {
        show: true
      }
    },

    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    }
  };

  return <ReactApexChart options={chartOptions} series={series} type={type} height={height} />;
};

BasicBarChart.propTypes = {
  type: PropTypes.string,
  sparkline: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number)
    })
  )
};

export default BasicBarChart;
