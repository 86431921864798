import {
  BooleanField,
  BooleanInput,
  CoverField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  ImpersonateUserButton,
  List,
  SearchInput,
  SelectInput,
  SimpleList,
  TextField
} from '@applica-software-guru/react-admin';
import { Chip, useMediaQuery } from '@mui/material';
import { SchoolSelectInput } from 'components/ra-inputs';

import PropTypes from 'prop-types';

const sortRoles = (roles, configuredRoles) => {
  return roles
    ?.filter((r) => configuredRoles.find((cr) => cr.id === r))
    .sort((a, b) => {
      const aIndex = configuredRoles.findIndex((r) => r.id === a);
      const bIndex = configuredRoles.findIndex((r) => r.id === b);

      return aIndex - bIndex;
    });
};
const UserList = ({ configuredRoles }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      perPage={25}
      filters={[
        <SearchInput key="keyword" source="keyword" alwaysOn fullWidth />,
        <SelectInput key="role" source="role" choices={configuredRoles} alwaysOn emptyText="ra.action.view_all" display="legend" />,
        <BooleanInput key="active" source="active" display="legend" />,
        <SchoolSelectInput key="schoolId" source="schoolId" display="legend" alwaysOn />
      ]}
    >
      {isSmall ? (
        <SimpleList
          leftAvatar={(record) => <CoverField source="image" circle width={50} height={50} justify="flex-end" record={record} />}
          primaryText={(record) => record.name}
          secondaryText={(record) => record.email}
        />
      ) : (
        <Datagrid rowClick="edit">
          <CoverField source="image" sortable={false} label="" width={50} height={50} circle />
          <BooleanField source="active" />
          <TextField source="name" sortBy="username" />
          <EmailField source="email" />
          <DateField source="registrationDate" showtime />
          <FunctionField
            label="ra.user.roles"
            render={(record) =>
              sortRoles(record?.roles, configuredRoles)?.map((role) => (
                <Chip label={configuredRoles.find((r) => r.id === role)?.name} key={role} sx={{ mr: 1, mt: 1 }} color="secondary" />
              ))
            }
          />
          <ImpersonateUserButton />
        </Datagrid>
      )}
    </List>
  );
};

UserList.propTypes = {
  configuredRoles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired
};

export default UserList;
